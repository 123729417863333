import { WSAlert, WSPanel, WSSidebar } from "@wingspanhq/fe-component-library";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useGetPayerEngagementQuery } from "../../../../query/payerEngagements/queries/useGetPayerEngagementQuery";
import { WSQueries } from "../../../../query/WSQuery";
import { IPayerEngagementResponse } from "@wingspanhq/payments/dist/interfaces/api/payerPayeeEngagement";
import { getParentPath } from "../../../../utils/goToParentRoute";
import { EngagementInfoPanel } from "./EngagementInfoPanel";
import React, { useState } from "react";
import { getEngagementEligibilityRequirementsCompleted } from "../../utils/engagement";
import {
  IPayerResponse,
  PayerPayeeEngagementStatus
} from "@wingspanhq/payments/dist/interfaces";
import { PayerIntegrationWidget } from "../../components/IntegrationsSection/PayerIntegrationWidget";
import { usePayerQuery } from "../../../../query/payers/queries/usePayerQuery";
import { selectorPayerEngagementNameTmp } from "../../../Payees/selectors/selectorPayerEngagementsTmp";
import { RequirementsTable } from "../../../Requirements/components/RequirementsTable";
import { RequirementContextType } from "../../../Requirements/types";
import { selectorRequirementsFromEngagement } from "../../../Requirements/selectors/selectorRequirementsFromEngagement";
import { usePayerEngagementsListQuery } from "../../../../query/payerEngagements/queries/usePayerEngagementsListQuery";
import { selectorIsEngagementsActiveForPayer } from "../../../Payees/selectors/selectorIsEngagementsActiveForPayer";
import { selectorPayerPayeeEngagementName } from "../../../Payees/selectors/selectorPayerPayeeEngagementName";
import { useUserId } from "../../../../query/hooks/helpers";

export const RouteEngagementDetails: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ clientId: string; engagementId: string }>();
  const { clientId, engagementId } = match.params;
  const queryEngagement = useGetPayerEngagementQuery(clientId, engagementId);
  const queryPayer = usePayerQuery(clientId);
  const queryPayerEngagements = usePayerEngagementsListQuery(clientId, {
    refetchOnMount: false
  });

  const backPath = location.state?.backPath || getParentPath(history);

  return (
    <WSSidebar.Container
      onClose={() => {
        history.replace(backPath);
      }}
    >
      <WSQueries
        queries={{ queryEngagement, queryPayerEngagements, queryPayer }}
      >
        {({
          queryEngagementData: payerEngagement,
          queryPayerEngagementsData,
          queryPayerData
        }) => {
          const isEngagementActiveForClient = selectorIsEngagementsActiveForPayer(
            queryPayerEngagementsData
          );

          return (
            <WSSidebar.Layout
              header={
                isEngagementActiveForClient
                  ? selectorPayerPayeeEngagementName(payerEngagement, true)
                  : selectorPayerEngagementNameTmp(payerEngagement, true)
              }
            >
              <Inner
                isEngagementActiveForClient={isEngagementActiveForClient}
                payerEngagement={payerEngagement}
                payer={queryPayerData}
              />
            </WSSidebar.Layout>
          );
        }}
      </WSQueries>
    </WSSidebar.Container>
  );
};

const Inner: React.FC<{
  payerEngagement: IPayerEngagementResponse;
  isEngagementActiveForClient: boolean;
  payer: IPayerResponse;
}> = ({ payerEngagement, isEngagementActiveForClient, payer }) => {
  const userId = useUserId();
  const [showWarning, setShowWarning] = useState<boolean>(true);

  const areRequirementsCompleted = getEngagementEligibilityRequirementsCompleted(
    payerEngagement
  );
  const isArchived =
    payerEngagement.status === PayerPayeeEngagementStatus.Inactive;

  const requirements = selectorRequirementsFromEngagement(payerEngagement);

  return (
    <>
      <EngagementInfoPanel
        name={
          isEngagementActiveForClient
            ? selectorPayerPayeeEngagementName(payerEngagement, true)
            : selectorPayerEngagementNameTmp(payerEngagement, true)
        }
        mb="XL"
      />
      {showWarning && !areRequirementsCompleted && !isArchived ? (
        <WSAlert
          theme="warning"
          icon="alert"
          title="Eligibility requirements incomplete"
          mb="L"
          onDismiss={() => setShowWarning(false)}
        >
          Complete all eligibility requirements to get paid.
        </WSAlert>
      ) : null}
      {requirements.length ? (
        <WSPanel noBorder p="NONE" mb="XL">
          <RequirementsTable
            payerId={payer.payerId}
            payeeId={userId}
            contextType={RequirementContextType.Payer}
            requirements={requirements}
            onSuccessAction={console.log}
            compact
            hideActions={
              payerEngagement.status === PayerPayeeEngagementStatus.Inactive
            }
          />
        </WSPanel>
      ) : null}
      <PayerIntegrationWidget
        isInactive={
          payerEngagement.status === PayerPayeeEngagementStatus.Inactive
        }
        payerId={payer.payerId}
        engagementId={payerEngagement.payerPayeeEngagementId}
        customerId={
          payerEngagement?.payeeOwnedData?.integration?.quickbooks?.customerId
        }
        itemId={
          payerEngagement?.payeeOwnedData?.integration?.quickbooks?.itemId
        }
      />
    </>
  );
};
