import {
  toWSDate,
  WSElement,
  WSFlexBox,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { IPayeeResponse } from "@wingspanhq/payments/dist/interfaces";

import { useInternalNotesModal } from "../InternalNotesModal/useInternalNotesModal";
import { FieldView } from "../FieldView";

type Props = {
  payee: IPayeeResponse;
};

export const InternalNotesWidget: React.FC<Props> = ({ payee }) => {
  const modalInternalNotes = useInternalNotesModal();

  const eventActor = payee.eventActors?.internalNotesUpdatedBy
    ? wsName({
        user: payee.eventActors?.internalNotesUpdatedBy
      }).getResolvedName()
    : "Unknown";

  const eventAt = toWSDate(payee.events?.internalNotesUpdatedAt, "monthDayYear")
    .formattedValue;

  return payee.payerOwnedData.internalNotes ? (
    <WSElement>
      <WSSectionToolbar
        mb="S"
        title="Internal notes"
        button={{
          label: "Edit",
          onClick: () => {
            modalInternalNotes.open({
              payee
            });
          }
        }}
      />
      <WSPanel>
        <WSFlexBox direction="column" gap="XL">
          <WSFlexBox direction="column" gap="S" alignItems="stretch">
            <WSText.ParagraphSm color="gray500" singleLine>
              Notes
            </WSText.ParagraphSm>
            <WSText.ParagraphSm color="gray700">
              {payee.payerOwnedData.internalNotes || "-"}
            </WSText.ParagraphSm>
          </WSFlexBox>

          <FieldView label="Last edited" value={`${eventActor} (${eventAt})`} />
        </WSFlexBox>
      </WSPanel>
    </WSElement>
  ) : null;
};
