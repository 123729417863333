import { EntityType } from "../../modules/Onboarding/types";

export interface TaxIdValidation {
  regex: RegExp;
  errorMessage: string;
  name: string;
  description: string;
  placeholder: string;
}

export const INTERNATIONAL_TAX_ID_VALIDATIONS: Record<
  string,
  Partial<Record<EntityType, TaxIdValidation>>
> = {
  AR: {
    Individual: {
      regex: /^\d{2}-\d{8}-\d{1}$/,
      errorMessage:
        "Please enter a valid 11-digit CUIL number (XX-XXXXXXXX-X).",
      name: "CUIL",
      description:
        "Unique Labor Identification Code for individuals in Argentina.",
      placeholder: "20-12345678-9"
    },
    Business: {
      regex: /^\d{2}-\d{8}-\d{1}$/,
      errorMessage:
        "Please enter a valid 11-digit CUIT number (XX-XXXXXXXX-X).",
      name: "CUIT",
      description: "Unique Tax Identification Key for businesses in Argentina.",
      placeholder: "30-12345678-9"
    }
  },
  AT: {
    Individual: {
      regex: /^\d{10}$/,
      errorMessage: "Please enter a valid 10-digit Tax Number.",
      name: "Tax Number",
      description:
        "10-digit tax identification number for individuals in Austria.",
      placeholder: "1234567890"
    },
    Business: {
      regex: /^ATU\d{8}$/,
      errorMessage:
        "Please enter a valid VAT Number (ATU followed by 8 digits).",
      name: "VAT Number",
      description: "VAT identification number for businesses in Austria.",
      placeholder: "ATU12345678"
    }
  },
  AU: {
    Individual: {
      regex: /^(?!0{8,9})\d{8,9}$/,
      errorMessage:
        "Please enter a valid TFN. It should be 8 or 9 digits and cannot be all zeros.",
      name: "Tax File Number (TFN)",
      description:
        "8 or 9 digit number issued to individuals and entities for tax purposes in Australia.",
      placeholder: "123456789"
    },
    Business: {
      regex: /^(?!0{11})\d{11}$/,
      errorMessage:
        "Please enter a valid 11-digit ABN. It cannot be all zeros.",
      name: "Australian Business Number (ABN)",
      description: "11-digit number used to identify businesses in Australia.",
      placeholder: "12345678901"
    }
  },
  BE: {
    Individual: {
      regex: /^\d{11}$/,
      errorMessage: "Please enter a valid 11-digit National Number.",
      name: "National Number",
      description:
        "11-digit national identification number for individuals in Belgium.",
      placeholder: "12345678901"
    },
    Business: {
      regex: /^[0-1]\d{9}$/,
      errorMessage: "Please enter a valid 10-digit Enterprise Number.",
      name: "Enterprise Number",
      description: "10-digit business identification number in Belgium.",
      placeholder: "0123456789"
    }
  },
  BD: {
    Individual: {
      regex: /^\d{10}|\d{13}|\d{17}$/,
      errorMessage:
        "Please enter a valid 10, 13, or 17-digit National ID Number.",
      name: "National ID Number",
      description:
        "National identification number for individuals in Bangladesh.",
      placeholder: "1234567890 or 1234567890123 or 12345678901234567"
    },
    Business: {
      regex: /^\d{12}$/,
      errorMessage: "Please enter a valid 12-digit TIN.",
      name: "Tax Identification Number (TIN)",
      description:
        "12-digit tax identification number for businesses in Bangladesh.",
      placeholder: "123456789012"
    }
  },
  BR: {
    Individual: {
      regex: /^(?!(\d)\1{10})\d{3}[-.]?\d{3}[-.]?\d{3}[-]?\d{2}$/,
      errorMessage:
        "Please enter a valid 11-digit CPF. It cannot be all the same digit.",
      name: "Cadastro de Pessoas Físicas (CPF)",
      description:
        "11-digit number issued to individuals for tax purposes in Brazil.",
      placeholder: "123.456.789-09"
    },
    Business: {
      regex: /^(?!(\d)\1{13})\d{2}[-.]?\d{3}[-.]?\d{3}[-/]?\d{4}[-]?\d{2}$/,
      errorMessage:
        "Please enter a valid 14-digit CNPJ. It cannot be all the same digit.",
      name: "Cadastro Nacional da Pessoa Jurídica (CNPJ)",
      description:
        "14-digit number issued to businesses for tax purposes in Brazil.",
      placeholder: "12.345.678/0001-90"
    }
  },
  CA: {
    Individual: {
      regex: /^(?!0{3}|8{3}|9{3})\d{3}[-]?\d{3}[-]?\d{3}$/,
      errorMessage:
        "Please enter a valid 9-digit SIN. It cannot start with 000, 800, or 900.",
      name: "Social Insurance Number (SIN)",
      description:
        "9-digit number used for various government programs in Canada.",
      placeholder: "123-456-789"
    },
    Business: {
      regex: /^\d{9}(RC\d{4}|)$/,
      errorMessage:
        "Please enter a valid BN. Format: 9 digits optionally followed by RC and 4 digits.",
      name: "Business Number (BN)",
      description:
        "9-digit number assigned by the CRA to businesses in Canada, sometimes followed by RC and 4 digits.",
      placeholder: "123456789 or 123456789RC0001"
    }
  },
  CH: {
    Individual: {
      regex: /^756[-.]?\d{4}[-.]?\d{4}[-.]?\d{2}$/,
      errorMessage:
        "Please enter a valid AHV number starting with 756, followed by 11 digits.",
      name: "AHV number (AHV-Nr.)",
      description:
        "13-digit social security number in Switzerland, starting with 756.",
      placeholder: "756.1234.5678.90"
    },
    Business: {
      regex: /^CHE[-]?\d{3}[-.]?\d{3}[-.]?\d{3}$/,
      errorMessage:
        "Please enter a valid UID starting with CHE, followed by 9 digits.",
      name: "UID (Unternehmens-Identifikationsnummer)",
      description:
        "Business identification number in Switzerland, starting with CHE followed by 9 digits.",
      placeholder: "CHE-123.456.789"
    }
  },
  CL: {
    Individual: {
      regex: /^\d{7,8}-[0-9K]$/,
      errorMessage:
        "Please enter a valid RUT number (7-8 digits followed by a hyphen and a digit or K).",
      name: "Rol Único Tributario (RUT)",
      description: "Tax identification number for individuals in Chile.",
      placeholder: "12345678-9"
    },
    Business: {
      regex: /^\d{7,8}-[0-9K]$/,
      errorMessage:
        "Please enter a valid RUT number (7-8 digits followed by a hyphen and a digit or K).",
      name: "Rol Único Tributario (RUT)",
      description: "Tax identification number for businesses in Chile.",
      placeholder: "76543210-K"
    }
  },
  CO: {
    Individual: {
      regex: /^[1-9]\d{3,9}$/,
      errorMessage:
        "Please enter a valid CC number. It should be 4-10 digits and not start with 0.",
      name: "Cédula de Ciudadanía (CC)",
      description: "4-10 digit identification number for citizens in Colombia.",
      placeholder: "1234567890"
    },
    Business: {
      regex: /^\d{9}(-\d)?$/,
      errorMessage:
        "Please enter a valid NIT. Format: 9 digits, optionally followed by a hyphen and check digit.",
      name: "Número de Identificación Tributaria (NIT)",
      description:
        "9-digit tax identification number for businesses in Colombia, optionally followed by a check digit.",
      placeholder: "123456789-0"
    }
  },
  CZ: {
    Individual: {
      regex: /^\d{6}\/{0,1}\d{3,4}$/,
      errorMessage:
        "Please enter a valid Birth Number (6 digits, optional slash, followed by 3-4 digits).",
      name: "Birth Number (Rodné číslo)",
      description:
        "Identification number for individuals in the Czech Republic.",
      placeholder: "901231/1234"
    },
    Business: {
      regex: /^\d{8}$/,
      errorMessage: "Please enter a valid 8-digit IČO.",
      name: "Identification Number (IČO)",
      description:
        "8-digit identification number for businesses in the Czech Republic.",
      placeholder: "12345678"
    }
  },
  DE: {
    Individual: {
      regex: /^(?!0{11})\d{11}$/,
      errorMessage:
        "Please enter a valid 11-digit Steuer-IdNr. It cannot be all zeros.",
      name: "Steueridentifikationsnummer (Steuer-IdNr)",
      description:
        "11-digit tax identification number for individuals in Germany.",
      placeholder: "12345678901"
    },
    Business: {
      regex: /^DE\d{9}$/,
      errorMessage:
        "Please enter a valid USt-IdNr. It should start with DE followed by 9 digits.",
      name: "Umsatzsteuer-Identifikationsnummer (USt-IdNr)",
      description:
        "VAT identification number for businesses in Germany, starting with DE followed by 9 digits.",
      placeholder: "DE123456789"
    }
  },
  DK: {
    Individual: {
      regex: /^\d{6}-\d{4}$/,
      errorMessage: "Please enter a valid CPR number (DDMMYY-XXXX).",
      name: "CPR Number",
      description: "Central Person Register number for individuals in Denmark.",
      placeholder: "010190-1234"
    },
    Business: {
      regex: /^\d{8}$/,
      errorMessage: "Please enter a valid 8-digit CVR number.",
      name: "CVR Number",
      description:
        "Central Business Register number for businesses in Denmark.",
      placeholder: "12345678"
    }
  },
  EG: {
    Individual: {
      regex: /^\d{14}$/,
      errorMessage: "Please enter a valid 14-digit National ID Number.",
      name: "National ID Number",
      description:
        "14-digit national identification number for individuals in Egypt.",
      placeholder: "12345678901234"
    },
    Business: {
      regex: /^\d{9}$/,
      errorMessage: "Please enter a valid 9-digit Tax Registration Number.",
      name: "Tax Registration Number",
      description: "9-digit tax identification number for businesses in Egypt.",
      placeholder: "123456789"
    }
  },
  ES: {
    Individual: {
      regex: /^[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/,
      errorMessage:
        "Please enter a valid NIF. Format: 8 digits + 1 letter, or 1 letter + 7 digits + 1 letter.",
      name: "Número de Identificación Fiscal (NIF)",
      description:
        "9-character tax identification number for individuals in Spain.",
      placeholder: "12345678Z or X1234567L"
    },
    Business: {
      regex: /^[ABCDEFGHJKLMNPQRSUVW]\d{7}[0-9A-J]$/,
      errorMessage:
        "Please enter a valid CIF. Format: 1 letter + 7 digits + 1 character.",
      name: "Código de Identificación Fiscal (CIF)",
      description:
        "9-character tax identification number for businesses in Spain.",
      placeholder: "B12345678"
    }
  },
  FI: {
    Individual: {
      regex: /^\d{6}[+-A]\d{3}[0-9A-Z]$/,
      errorMessage:
        "Please enter a valid Personal Identity Code (DDMMYY[+-A]NNNC).",
      name: "Personal Identity Code",
      description: "National identification number for individuals in Finland.",
      placeholder: "010190-123A"
    },
    Business: {
      regex: /^\d{7}-\d$/,
      errorMessage:
        "Please enter a valid Business ID (7 digits, hyphen, check digit).",
      name: "Business ID (Y-tunnus)",
      description: "Identification number for businesses in Finland.",
      placeholder: "1234567-8"
    }
  },
  FR: {
    Individual: {
      regex: /^[1-2]\d{2}(0[1-9]|1[0-2])(2[AB]|[0-9]{2})\d{5}\d{2}$/,
      errorMessage: "Please enter a valid 15-digit NIR.",
      name: "Numéro d'Inscription au Répertoire (NIR)",
      description: "15-digit social security number for individuals in France.",
      placeholder: "187112B12345612"
    },
    Business: {
      regex: /^\d{3}[-]?\d{3}[-]?\d{3}$/,
      errorMessage:
        "Please enter a valid 9-digit SIREN, with or without hyphens.",
      name: "SIREN",
      description: "9-digit business identification number in France.",
      placeholder: "123-456-789"
    }
  },
  GB: {
    Individual: {
      regex: /^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]$/,
      errorMessage:
        "Please enter a valid NINO. Format: 2 letters, 6 digits, 1 letter (e.g., AB123456C).",
      name: "National Insurance Number (NINO)",
      description:
        "Unique identifier for individuals in the UK social security system.",
      placeholder: "AB123456C"
    },
    Business: {
      regex: /^(\d{8}|\w{2}\d{6})$/,
      errorMessage:
        "Please enter a valid CRN. Format: 8 digits or 2 letters followed by 6 digits.",
      name: "Company Registration Number (CRN)",
      description:
        "Unique identifier issued by Companies House to limited companies in the UK.",
      placeholder: "12345678 or AB123456"
    }
  },
  HK: {
    Individual: {
      regex: /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/,
      errorMessage: "Please enter a valid HKID (e.g., AB123456(7)).",
      name: "Hong Kong Identity Card (HKID) Number",
      description: "Identification number for individuals in Hong Kong.",
      placeholder: "AB123456(7)"
    },
    Business: {
      regex: /^\d{8}$/,
      errorMessage:
        "Please enter a valid 8-digit Business Registration Number.",
      name: "Business Registration Number",
      description: "8-digit registration number for businesses in Hong Kong.",
      placeholder: "12345678"
    }
  },
  ID: {
    Individual: {
      regex: /^\d{2}[-.]?\d{3}[-.]?\d{3}[-.]?\d[-.]?\d{3}[-.]?\d{3}$/,
      errorMessage:
        "Please enter a valid 15-digit NPWP, with or without separators.",
      name: "Nomor Pokok Wajib Pajak (NPWP)",
      description:
        "15-digit tax identification number for individuals in Indonesia.",
      placeholder: "12.345.678.9-012.345"
    },
    Business: {
      regex: /^\d{2}[-.]?\d{3}[-.]?\d{3}[-.]?\d[-.]?\d{3}[-.]?\d{3}$/,
      errorMessage:
        "Please enter a valid 15-digit NPWP, with or without separators.",
      name: "Nomor Pokok Wajib Pajak (NPWP)",
      description:
        "15-digit tax identification number for businesses in Indonesia.",
      placeholder: "12.345.678.9-012.345"
    }
  },
  IE: {
    Individual: {
      regex: /^\d{7}[A-Z]{1,2}$/,
      errorMessage:
        "Please enter a valid PPS Number (7 digits followed by 2 letters).",
      name: "PPS Number",
      description: "Personal Public Service Number for individuals in Ireland.",
      placeholder: "1234567AB"
    },
    Business: {
      regex: /^\d{7}[A-W][A-I]$|^[0-9]{9}[A-Z]$/,
      errorMessage:
        "Please enter a valid Tax Reference Number (7 digits + 2 letters, or 9 digits + 1 letter).",
      name: "Tax Reference Number",
      description: "Tax identification number for businesses in Ireland.",
      placeholder: "1234567AB or 123456789X"
    }
  },
  IL: {
    Individual: {
      regex: /^\d{9}$/,
      errorMessage: "Please enter a valid 9-digit ID Number.",
      name: "ID Number",
      description:
        "9-digit national identification number for individuals in Israel.",
      placeholder: "123456789"
    },
    Business: {
      regex: /^\d{9}$/,

      errorMessage: "Please enter a valid 9-digit Company Number.",
      name: "Company Number",
      description: "9-digit registration number for businesses in Israel.",
      placeholder: "123456789"
    }
  },
  IN: {
    Individual: {
      regex: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
      errorMessage:
        "Please enter a valid 10-character PAN. Format: ABCDE1234F.",
      name: "Permanent Account Number (PAN)",
      description:
        "10-character alphanumeric identifier issued to individuals and entities in India.",
      placeholder: "ABCDE1234F"
    },
    Business: {
      regex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z]{1}Z[0-9A-Z]$/,
      errorMessage: "Please enter a valid 15-character GSTIN.",
      name: "Goods and Services Tax Identification Number (GSTIN)",
      description:
        "15-character identification number for businesses registered under GST in India.",
      placeholder: "27AAPFU0939F1Z5"
    }
  },
  IR: {
    Individual: {
      regex: /^\d{10}$/,
      errorMessage: "Please enter a valid 10-digit National ID Number.",
      name: "National ID Number",
      description:
        "10-digit national identification number for individuals in Iran.",
      placeholder: "1234567890"
    },
    Business: {
      regex: /^\d{11}$/,
      errorMessage:
        "Please enter a valid 11-digit National ID Number for Legal Entities.",
      name: "National ID Number for Legal Entities",
      description: "11-digit identification number for businesses in Iran.",
      placeholder: "12345678901"
    }
  },
  IT: {
    Individual: {
      regex: /^[A-Z]{6}\d{2}[ABCDEHLMPRST]\d{2}[A-Z]\d{3}[A-Z]$/,
      errorMessage: "Please enter a valid 16-character Codice Fiscale.",
      name: "Codice Fiscale",
      description:
        "16-character alphanumeric tax code for individuals in Italy.",
      placeholder: "RSSMRA80A01H501U"
    },
    Business: {
      regex: /^IT?\d{11}$/,
      errorMessage:
        "Please enter a valid Partita IVA. Format: IT followed by 11 digits, or just 11 digits.",
      name: "Partita IVA",
      description:
        "VAT number for businesses in Italy, consisting of 11 digits, optionally preceded by IT.",
      placeholder: "IT12345678901"
    }
  },
  JP: {
    Individual: {
      regex: /^\d{4}[-]?\d{4}[-]?\d{4}$/,
      errorMessage:
        "Please enter a valid 12-digit My Number, with or without hyphens.",
      name: "My Number",
      description:
        "12-digit social security and tax identification number for individuals in Japan.",
      placeholder: "1234-5678-9012"
    },
    Business: {
      regex: /^\d{13}$/,
      errorMessage: "Please enter a valid 13-digit Corporate Number.",
      name: "Corporate Number",
      description:
        "13-digit identification number for corporations and other organizations in Japan.",
      placeholder: "1234567890123"
    }
  },
  KR: {
    Individual: {
      regex: /^\d{6}[-]?[1-4]\d{6}$/,
      errorMessage:
        "Please enter a valid 13-digit RRN, with or without a hyphen.",
      name: "Resident Registration Number (RRN)",
      description: "13-digit number issued to all residents of South Korea.",
      placeholder: "900101-1234567"
    },
    Business: {
      regex: /^\d{3}[-]?\d{2}[-]?\d{5}$/,
      errorMessage:
        "Please enter a valid 10-digit Business Registration Number, with or without hyphens.",
      name: "Business Registration Number",
      description: "10-digit number issued to businesses in South Korea.",
      placeholder: "123-45-67890"
    }
  },
  KZ: {
    Individual: {
      regex: /^\d{12}$/,
      errorMessage: "Please enter a valid 12-digit IIN.",
      name: "Individual Identification Number (IIN)",
      description:
        "12-digit identification number for individuals in Kazakhstan.",
      placeholder: "123456789012"
    },
    Business: {
      regex: /^\d{12}$/,
      errorMessage: "Please enter a valid 12-digit BIN.",
      name: "Business Identification Number (BIN)",
      description:
        "12-digit identification number for businesses in Kazakhstan.",
      placeholder: "123456789012"
    }
  },
  LK: {
    Individual: {
      regex: /^(?:\d{9}[VvXx]|\d{12})$/,
      errorMessage:
        "Please enter a valid NIC number. Old format: 9 digits followed by V or X. New format: 12 digits.",
      name: "National Identity Card (NIC) Number",
      description:
        "National ID number for individuals in Sri Lanka. Can be in old (10 characters) or new (12 digits) format.",
      placeholder: "123456789V or 123456789012"
    },
    Business: {
      regex: /^[A-Z]{2}\d{5}$/,
      errorMessage:
        "Please enter a valid BRN. Format: 2 letters followed by 5 digits.",
      name: "Business Registration Number (BRN)",
      description: "7-character business registration number in Sri Lanka.",
      placeholder: "PV12345"
    }
  },
  MX: {
    Individual: {
      regex: /^[A-ZÑ&]{4}\d{6}[A-V1-9][A-Z1-9][0-9A-Z]$/,
      errorMessage: "Please enter a valid 13-character individual RFC.",
      name: "Registro Federal de Contribuyentes (RFC)",
      description:
        "13-character tax identification code for individuals in Mexico.",
      placeholder: "MELM8305281H0"
    },
    Business: {
      regex: /^[A-ZÑ&]{3}\d{6}[A-V1-9][A-Z1-9][0-9A-Z]$/,
      errorMessage: "Please enter a valid 12-character business RFC.",
      name: "Registro Federal de Contribuyentes (RFC)",
      description:
        "12-character tax identification code for businesses in Mexico.",
      placeholder: "TRE150109RC5"
    }
  },
  MY: {
    Individual: {
      regex: /^\d{6}-\d{2}-\d{4}$/,
      errorMessage: "Please enter a valid MyKad number (YYMMDD-PB-###G).",
      name: "MyKad Number",
      description:
        "National identification number for individuals in Malaysia.",
      placeholder: "990101-14-1234"
    },
    Business: {
      regex: /^[A-Z]{1,2}\d{10}$/,
      errorMessage: "Please enter a valid Business Registration Number.",
      name: "Business Registration Number",
      description: "Registration number for businesses in Malaysia.",
      placeholder: "AB1234567890"
    }
  },
  NL: {
    Individual: {
      regex: /^(?!0{9})\d{9}$/,
      errorMessage: "Please enter a valid 9-digit BSN. It cannot be all zeros.",
      name: "Burgerservicenummer (BSN)",
      description:
        "9-digit personal identification number used in the Netherlands.",
      placeholder: "123456782"
    },
    Business: {
      regex: /^\d{9}$/,
      errorMessage: "Please enter a valid 9-digit RSIN.",
      name: "Rechtspersonen en Samenwerkingsverbanden Informatienummer (RSIN)",
      description:
        "9-digit number for legal entities and partnerships in the Netherlands.",
      placeholder: "123456789"
    }
  },
  NO: {
    Individual: {
      regex: /^\d{11}$/,
      errorMessage: "Please enter a valid 11-digit National Identity Number.",
      name: "National Identity Number",
      description: "11-digit identification number for individuals in Norway.",
      placeholder: "12345678901"
    },
    Business: {
      regex: /^\d{9}$/,
      errorMessage: "Please enter a valid 9-digit Organization Number.",
      name: "Organization Number",
      description: "9-digit registration number for businesses in Norway.",
      placeholder: "123456789"
    }
  },
  PH: {
    Individual: {
      regex: /^\d{3}[-]?\d{3}[-]?\d{3}[-]?\d{3}$/,
      errorMessage:
        "Please enter a valid 12-digit TIN, with or without hyphens.",
      name: "Tax Identification Number (TIN)",
      description:
        "12-digit tax identification number for individuals in the Philippines.",
      placeholder: "123-456-789-000"
    },
    Business: {
      regex: /^\d{3}[-]?\d{3}[-]?\d{3}[-]?\d{3}$/,
      errorMessage:
        "Please enter a valid 12-digit TIN, with or without hyphens.",
      name: "Tax Identification Number (TIN)",
      description:
        "12-digit tax identification number for businesses in the Philippines.",
      placeholder: "123-456-789-000"
    }
  },
  PK: {
    Individual: {
      regex: /^\d{13}$/,
      errorMessage: "Please enter a valid 13-digit CNIC Number.",
      name: "Computerized National Identity Card (CNIC) Number",
      description:
        "13-digit national identification number for individuals in Pakistan.",
      placeholder: "1234512345671"
    },
    Business: {
      regex: /^\d{7}-\d{1}$/,
      errorMessage:
        "Please enter a valid NTN (7 digits followed by a check digit).",
      name: "National Tax Number (NTN)",
      description: "Tax identification number for businesses in Pakistan.",
      placeholder: "1234567-1"
    }
  },
  PL: {
    Individual: {
      regex: /^\d{11}$/,
      errorMessage: "Please enter a valid 11-digit PESEL number.",
      name: "PESEL",
      description:
        "11-digit national identification number for individuals in Poland.",
      placeholder: "12345678901"
    },
    Business: {
      regex: /^\d{10}$/,
      errorMessage: "Please enter a valid 10-digit NIP number.",
      name: "NIP",
      description:
        "10-digit tax identification number for businesses in Poland.",
      placeholder: "1234567890"
    }
  },
  PT: {
    Individual: {
      regex: /^\d{9}$/,
      errorMessage: "Please enter a valid 9-digit NIF.",
      name: "Número de Identificação Fiscal (NIF)",
      description: "Tax identification number for individuals in Portugal.",
      placeholder: "123456789"
    },
    Business: {
      regex: /^\d{9}$/,
      errorMessage: "Please enter a valid 9-digit NIPC.",
      name: "Número de Identificação de Pessoa Coletiva (NIPC)",
      description: "Tax identification number for businesses in Portugal.",
      placeholder: "123456789"
    }
  },
  RO: {
    Individual: {
      regex: /^\d{13}$/,
      errorMessage: "Please enter a valid 13-digit CNP.",
      name: "Personal Numerical Code (CNP)",
      description:
        "13-digit personal identification number for individuals in Romania.",
      placeholder: "1234567890123"
    },
    Business: {
      regex: /^RO\d{2,10}$/,
      errorMessage:
        "Please enter a valid CUI/CIF (RO followed by 2-10 digits).",
      name: "CUI/CIF",
      description: "Tax identification code for businesses in Romania.",
      placeholder: "RO12345678"
    }
  },
  RU: {
    Individual: {
      regex: /^\d{12}$/,
      errorMessage: "Please enter a valid 12-digit INN for individuals.",
      name: "Individual Taxpayer Number (INN)",
      description:
        "12-digit tax identification number for individuals in Russia.",
      placeholder: "123456789012"
    },
    Business: {
      regex: /^\d{10}$/,
      errorMessage: "Please enter a valid 10-digit INN for businesses.",
      name: "Business Taxpayer Number (INN)",
      description:
        "10-digit tax identification number for businesses in Russia.",
      placeholder: "1234567890"
    }
  },
  SA: {
    Individual: {
      regex: /^[1-2]\d{9}$/,
      errorMessage:
        "Please enter a valid 10-digit National ID Number starting with 1 or 2.",
      name: "National ID Number",
      description:
        "10-digit national identification number for individuals in Saudi Arabia.",
      placeholder: "1234567890"
    },
    Business: {
      regex: /^\d{10}$/,
      errorMessage:
        "Please enter a valid 10-digit Commercial Registration Number.",
      name: "Commercial Registration Number",
      description:
        "10-digit registration number for businesses in Saudi Arabia.",
      placeholder: "1234567890"
    }
  },
  SE: {
    Individual: {
      regex: /^(\d{6}[-]\d{4}|\d{12})$/,
      errorMessage:
        "Please enter a valid Personal Identity Number (10 or 12 digits).",
      name: "Personal Identity Number",
      description: "National identification number for individuals in Sweden.",
      placeholder: "YYMMDD-XXXX or YYYYMMDDXXXX"
    },
    Business: {
      regex: /^\d{10}$/,
      errorMessage: "Please enter a valid 10-digit Organization Number.",
      name: "Organization Number",
      description: "10-digit identification number for businesses in Sweden.",
      placeholder: "1234567890"
    }
  },
  SG: {
    Individual: {
      regex: /^[STFG]\d{7}[A-Z]$/,
      errorMessage: "Please enter a valid NRIC/FIN (e.g., S1234567D).",
      name: "NRIC/FIN",
      description:
        "National Registration Identity Card number for individuals in Singapore.",
      placeholder: "S1234567D"
    },
    Business: {
      regex: /^\d{8}[A-Z]$/,
      errorMessage: "Please enter a valid UEN (e.g., 12345678K).",
      name: "Unique Entity Number (UEN)",
      description: "Identification number for businesses in Singapore.",
      placeholder: "12345678K"
    }
  },
  TH: {
    Individual: {
      regex: /^\d{13}$/,
      errorMessage: "Please enter a valid 13-digit Citizen ID.",
      name: "Citizen ID",
      description:
        "13-digit national identification number for individuals in Thailand.",
      placeholder: "1234567890123"
    },
    Business: {
      regex: /^\d{13}$/,
      errorMessage:
        "Please enter a valid 13-digit Company Registration Number.",
      name: "Company Registration Number",
      description: "13-digit registration number for businesses in Thailand.",
      placeholder: "1234567890123"
    }
  },
  TR: {
    Individual: {
      regex: /^[1-9]\d{10}$/,
      errorMessage:
        "Please enter a valid 11-digit Turkish Identification Number. It cannot start with 0.",
      name: "Turkish Identification Number",
      description: "11-digit identification number for individuals in Turkey.",
      placeholder: "12345678901"
    },
    Business: {
      regex: /^\d{10}$/,
      errorMessage: "Please enter a valid 10-digit Tax Identification Number.",
      name: "Tax Identification Number",
      description:
        "10-digit tax identification number for businesses in Turkey.",
      placeholder: "1234567890"
    }
  },
  TW: {
    Individual: {
      regex: /^[A-Z][12]\d{8}$/,
      errorMessage:
        "Please enter a valid National ID. Format: 1 letter, followed by 1 or 2, then 8 digits.",
      name: "National Identification Number",
      description: "10-character national ID for individuals in Taiwan.",
      placeholder: "A123456789"
    },
    Business: {
      regex: /^\d{8}$/,
      errorMessage: "Please enter a valid 8-digit Unified Business Number.",
      name: "Unified Business Number",
      description: "8-digit tax ID number for businesses in Taiwan.",
      placeholder: "12345678"
    }
  },
  US: {
    Individual: {
      regex: /^(?!000|666|9\d{2})\d{3}[-]?\d{2}[-]?\d{4}$/,
      errorMessage:
        "Please enter a valid 9-digit SSN. It should not start with 000, 666, or 900-999.",
      name: "Social Security Number (SSN)",
      description:
        "9-digit number issued to U.S. citizens, permanent residents, and temporary working residents.",
      placeholder: "123-45-6789"
    },
    Business: {
      regex: /^\d{2}[-]?\d{7}$/,
      errorMessage:
        "Please enter a valid 9-digit EIN. Format: XX-XXXXXXX or XXXXXXXXX.",
      name: "Employer Identification Number (EIN)",
      description: "9-digit number assigned by the IRS to business entities.",
      placeholder: "12-3456789"
    }
  },
  VN: {
    Individual: {
      regex: /^\d{9}|\d{12}$/,
      errorMessage:
        "Please enter a valid 9 or 12-digit Citizen Identity Number.",
      name: "Citizen Identity Number",
      description: "National identification number for individuals in Vietnam.",
      placeholder: "123456789 or 123456789012"
    },
    Business: {
      regex: /^\d{10}$/,
      errorMessage: "Please enter a valid 10-digit Tax Identification Number.",
      name: "Tax Identification Number",
      description:
        "10-digit tax identification number for businesses in Vietnam.",
      placeholder: "1234567890"
    }
  },
  ZA: {
    Individual: {
      regex: /^\d{13}$/,
      errorMessage: "Please enter a valid 13-digit ID Number.",
      name: "ID Number",
      description:
        "13-digit national identification number for individuals in South Africa.",
      placeholder: "1234567890123"
    },
    Business: {
      regex: /^\d{10}$/,
      errorMessage:
        "Please enter a valid 10-digit Company Registration Number.",
      name: "Company Registration Number",
      description:
        "10-digit registration number for businesses in South Africa.",
      placeholder: "1234567890"
    }
  }
};

// https://docs.wingspan.app/v2.0/reference/customer-onboarding#7-testing-customer-data-apis
export const TEST_TAX_IDS = [
  "111111111",
  "222222222",
  "333333333",
  "444444444"
];
