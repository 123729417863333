import { selectorCountryName } from "../../../shared/selectors/selectorCountryName";
import { Section } from "./Section";
import { FormData } from "./FormAccountType";

type Props = {
  data: FormData;
  onEdit?: () => void;
};

export const ReviewAccountType: React.FC<Props> = ({ data, onEdit }) => (
  <Section
    title="Account type"
    onEdit={onEdit}
    data={[
      {
        label: "Country of operation",
        value: selectorCountryName(data.country)
      },
      {
        label: "Account type",
        value: data.type
      }
    ]}
  />
);
