import { WSGrid } from "@wingspanhq/fe-component-library";
import { BankCardOTPModal } from "../components/BankCard/BankCardOTPModal";
import { AvailableBalance } from "../components/AvailableBalance";
import { CashbackBalance } from "../components/CashbackBalance";
import { Sidebar } from "../Sidebar";

export const RouteDashboard: React.FC = () => {
  // const qBankingAccount = useBankingAccount();
  // const isMigrationInProgress = selectorIsMigrationInProgress(
  //   qBankingAccount.data
  // );

  return (
    <WSGrid gutter={{ s: "3XL" }}>
      <WSGrid.Item span={{ m: "8" }}>
        <WSGrid mb="4XL">
          <WSGrid.Item span={{ m: "12", l: "6" }}>
            <AvailableBalance />
          </WSGrid.Item>
          <WSGrid.Item span={{ m: "12", l: "6" }}>
            {/* <CashbackBalance /> */}
          </WSGrid.Item>
        </WSGrid>

        {/*{qBankingAccount.data ? (*/}
        {/*  <BankCardsList*/}
        {/*    hideBanner*/}
        {/*    isMigraitonInProgress={isMigrationInProgress}*/}
        {/*    basePath="/member/wallet/dashboard/cards"*/}
        {/*  />*/}
        {/*) : null}*/}

        <BankCardOTPModal />
      </WSGrid.Item>
      <WSGrid.Item span={{ m: "4" }}>
        <Sidebar />
      </WSGrid.Item>
    </WSGrid>
  );
};
