import {
  copyText,
  useIsMobile,
  useWSSnackbar,
  WSAvatar,
  WSDivider,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSScreen,
  WSText
} from "@wingspanhq/fe-component-library";
import classNames from "classnames";
import { truncate } from "lodash";
import * as React from "react";
import { TouchEventHandler, useMemo } from "react";
import { createPPL } from "../../PersonalPayLink/utils";
import { CURRENT_YEAR } from "../../modules/1099NECFiling/constants/currentYear";
import { SwitchAccounts } from "../../modules/SelectOrganizationAccount/components/SwitchAccountsDropdown";
import { useCustomization } from "../../modules/customization";
import { useCustomizationQuery } from "../../query/customization/queries/useCustomizationQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useFeatureFlags } from "../../query/hooks/useFeatureFlags";
import { useNotifications } from "../../query/notifications/queries/useNotifications";
import { usePayeeTaxForms } from "../../query/taxForm/queries/usePayeeTaxForms";
import { useUserProfile } from "../../query/users/queries";
import { useShouldPauseAccount } from "../../shared/hooks/useShouldPauseAccount";
import { selectorCountOfVisibleNotifications } from "../../shared/selectors/selectorCountOfVisibleNotifications";
import { selectorIsEnterpriseUser } from "../../shared/selectors/selectorIsEnterpriseUser";
import { openIntercom } from "../../shared/utils/intercom";
import { plural } from "../../shared/utils/plural";
import { useAuthorizedScopeGroups } from "../../shared/utils/teamUtils";
import { useShowBatchImportsNav } from "../../userFeatures/useShowBatchImportsNav";
import { useShowCustomFieldsNav } from "../../userFeatures/useShowCustomFieldsNav";
import { useShowGetPaidNav } from "../../userFeatures/useShowGetPaidNav";
import { useShowPayeableAndPayrollSettingsNav } from "../../userFeatures/useShowPayeableAndPayrollSettingsNav";
import { useShowPaymentNav } from "../../userFeatures/useShowPaymentNav";
import { useShowPayoutNav } from "../../userFeatures/useShowPayoutNav";
import { useShowSendPaymentsNav } from "../../userFeatures/useShowSendPaymentsNav";
import { HeadwayNotification } from "../HeadwayNotification";
import { NEC_1099_ONLY_PAYERS } from "./1099OnlyPayers";
import { CollapsibleMenu } from "./CollapsibleMenu";
import { New } from "./New";
import { SideNavHeader } from "./SideNavHeader";
import { SideNavMenuItem } from "./SideNavMenuItem";
import { ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH } from "../../modules/EngagementRequirementsSettings";
import { SideNavRootLinkOption } from "./SideNavRootLinkOption";
import { SideNavRootOption } from "./SideNavRootOption";
import styles from "./styles.module.scss";
import { useIsEngagementsActive } from "../../shared/hooks/useIsEngagementsActive";

enum ExpandableSideNavMenuOptions {
  GetPaid = "GetPaid",
  SendPayments = "SendPayments",
  FinancialBenefits = "FinancialBenefits",
  LifestyleBenefits = "LifestyleBenefits",
  Reporting = "Reporting",
  DataAndIntegrations = "DataAndIntegrations",
  Settings = "Settings"
}

export interface SideNavMenuProps {
  isSideNavOpened?: boolean;
  setIsSideNavOpened?: (isDrawerOpened: boolean) => void;
  setIsDrawerOpened?: (isDrawerOpened: boolean) => void;
}

const SideNavMenu: React.FC<SideNavMenuProps> = ({
  isSideNavOpened,
  setIsSideNavOpened = () => {},
  setIsDrawerOpened = () => {}
}) => {
  const [topMenu, setTopMenu] = React.useState<string[]>([]);
  const [bottomMenu, setBottomMenu] = React.useState<string[]>([]);
  const { terminology } = useCustomization();
  const showGetPaidNav = useShowGetPaidNav();
  const showSendPaymentsNav = useShowSendPaymentsNav();
  const showPayeableAndPayrollSettingsNav = useShowPayeableAndPayrollSettingsNav();
  const showBatchImportsNav = useShowBatchImportsNav();
  const showCustomFieldsNav = useShowCustomFieldsNav();
  const showPaymentNav = useShowPaymentNav();
  const showPayoutNav = useShowPayoutNav();

  const { openSnackbar } = useWSSnackbar();
  const isMobile = useIsMobile();
  const userId = useUserId();
  const user = useUserProfile(userId);
  const queryNotifications = useNotifications();
  const shouldPauseAccountTaxes = useShouldPauseAccount("taxes");
  const shouldPauseAccountWallet = useShouldPauseAccount("wallet");
  const isEngagementsActive = useIsEngagementsActive();

  const {
    isAdminSessionExists,
    isRequestingUserSessionExists,
    isRequestingUserTokenFromURL,
    hasAdminScope,
    hasPaymentsScope,
    hasCollaboratorsScope,
    hasFinancesScope,
    hasDocumentsScope
  } = useAuthorizedScopeGroups();

  const customizationQuery = useCustomizationQuery();
  const hasEmailSnippets =
    Object.keys(customizationQuery.data?.emailCustomization?.templates ?? {})
      .length > 0;

  React.useEffect(() => {
    if (!isSideNavOpened) {
      setTopMenu([]);
      setBottomMenu([]);
    }
  }, [isSideNavOpened]);

  const featureFlagsQuery = useFeatureFlags();
  const queryPayerTaxForms = usePayeeTaxForms(CURRENT_YEAR);

  const onExpandMainMenuItem = (
    item: ExpandableSideNavMenuOptions,
    top = true
  ) => {
    if (!isSideNavOpened) {
      setIsSideNavOpened(true);
    }
    if (top) {
      if (topMenu.includes(item)) {
        setTopMenu(topMenu.filter(menu => menu !== item));
      } else {
        setTopMenu([...topMenu, item]);
        setBottomMenu([]);

        // TODO: Disabling it so I comment it. We mostly uncomment it soon.
        // if (!isMobile) {
        //   // NOTE: Navigate to first submenu option on expand
        //   history.push("/member/invoices");
        // }
      }
    } else {
      if (bottomMenu.includes(item)) {
        setBottomMenu(bottomMenu.filter(menu => menu !== item));
      } else {
        setBottomMenu([...bottomMenu, item]);
        setTopMenu([]);
        // if (!isMobile) {
        //   // NOTE: Navigate to first submenu option on expand
        //   history.push("/member/settings/account");
        // }
      }
    }
  };

  const isEnterpriseUser = user.data && selectorIsEnterpriseUser(user.data);

  const isPayeeOnTaxForms = useMemo(() => {
    return (
      featureFlagsQuery.data?.nec1099RecipientsTaxDocuments &&
      queryPayerTaxForms.data &&
      queryPayerTaxForms.data.length > 0 &&
      queryPayerTaxForms.data.some(taxForm => taxForm.memberId === userId)
    );
  }, [queryPayerTaxForms.data, featureFlagsQuery.data, userId]);

  const is1099OnlyRecipient = useMemo(() => {
    return (
      featureFlagsQuery.data?.nec1099Only &&
      queryPayerTaxForms.data?.length &&
      queryPayerTaxForms.data?.length >= 0 &&
      queryPayerTaxForms.data.every(taxForm =>
        NEC_1099_ONLY_PAYERS.includes(taxForm.clientId)
      )
    );
  }, [queryPayerTaxForms.data, featureFlagsQuery.data, isPayeeOnTaxForms]);

  return (
    <WSElement
      as="nav"
      className={classNames(isMobile ? styles.navMobile : styles.nav, {
        [styles.collapsed]: !isMobile && !isSideNavOpened
      })}
      data-testuseremail={user.data?.email}
    >
      <WSFlexBox direction="column" className={styles.navItemsContainer}>
        <WSScreen.TabletAndDesktop>
          <SideNavHeader
            p="M"
            isSideNavOpened={isSideNavOpened}
            setIsSideNavOpened={setIsSideNavOpened}
          />
        </WSScreen.TabletAndDesktop>

        {isMobile ? (
          <>
            <WSFlexBox.CenterY>
              {user.data?.profile.photo ? (
                <WSAvatar.Image
                  m="M"
                  size="L"
                  image={user.data?.profile.photo}
                />
              ) : (
                <WSAvatar.Image
                  m="M"
                  size="L"
                  image={
                    require("../../assets/images/defaultUserPhoto.svg").default
                  }
                />
              )}
              <WSElement>
                <WSText
                  color="gray700"
                  style={{ fontSize: "17px", lineHeight: "24px" }}
                >
                  <b>
                    {truncate(
                      `${user.data?.profile.firstName || ""} ${user.data
                        ?.profile.lastName || ""}`,
                      {
                        length: 20,
                        separator: " ",
                        omission: ""
                      }
                    )}
                  </b>
                </WSText>
                {/* {qActivity.data?.flows.settingsFoundingMemberInterest
                  ?.complete ? (
                  <WSText.ParagraphSm color="gray500">
                    Early Access Member 🌟
                  </WSText.ParagraphSm>
                ) : (
                  <WSButton.Link
                    onClick={async () => {
                      history.push("/member/settings/account/early-access");
                      setIsDrawerOpened(false);
                    }}
                  >
                    Request Early Access
                  </WSButton.Link>
                )} */}
              </WSElement>
            </WSFlexBox.CenterY>
            <WSDivider mb="XL" />
          </>
        ) : null}

        {isRequestingUserSessionExists ||
        isAdminSessionExists ||
        isRequestingUserTokenFromURL ? null : (
          <SwitchAccounts
            isSideNavOpened={!!isSideNavOpened}
            setIsSideNavOpened={
              isMobile ? setIsDrawerOpened : setIsSideNavOpened
            }
          />
        )}
        {!is1099OnlyRecipient && (
          <New
            isSideNavOpened={!!isSideNavOpened}
            setIsSideNavOpened={
              isMobile ? setIsDrawerOpened : setIsSideNavOpened
            }
          />
        )}

        <SideNavRootLinkOption
          isVisible={!is1099OnlyRecipient}
          to="/member/dashboard"
          testId="memberGettingStarted"
          label="Getting Started"
          icon="start"
          isSideNavOpened={isSideNavOpened}
          count={
            queryNotifications.data &&
            selectorCountOfVisibleNotifications(queryNotifications.data)
          }
        />

        <WSFlexBox direction="column" className={styles.topNavItems} mt="M">
          <CollapsibleMenu
            isVisible={
              showGetPaidNav && !is1099OnlyRecipient && hasPaymentsScope
            }
            isSideNavOpened={isSideNavOpened}
            icon="in"
            label="Get Paid"
            isExpanded={topMenu.includes(ExpandableSideNavMenuOptions.GetPaid)}
            onClick={() =>
              onExpandMainMenuItem(ExpandableSideNavMenuOptions.GetPaid)
            }
          >
            <SideNavMenuItem
              linkTo="/member/invoices"
              testId="paymentsInvoicing"
              isVisible={true}
              label={plural(terminology().getPaidInvoice)}
              exact
            />

            <SideNavMenuItem
              linkTo="/member/payers"
              testId="clients"
              isVisible={true}
              label={plural(terminology().getPaidClient)}
            />
          </CollapsibleMenu>

          <CollapsibleMenu
            isVisible={
              showSendPaymentsNav &&
              !is1099OnlyRecipient &&
              (hasPaymentsScope || hasCollaboratorsScope)
            }
            isSideNavOpened={isSideNavOpened}
            icon="out"
            label="Send Payments"
            isExpanded={topMenu.includes(
              ExpandableSideNavMenuOptions.SendPayments
            )}
            onClick={() =>
              onExpandMainMenuItem(ExpandableSideNavMenuOptions.SendPayments)
            }
          >
            <SideNavMenuItem
              linkTo="/member/invoices/payables"
              testId="paymentsPayables"
              isVisible={hasPaymentsScope}
              label={plural(terminology().sendPaymentsPayable)}
            />
            <SideNavMenuItem
              linkTo="/member/payees"
              testId="collaborators"
              isVisible={hasCollaboratorsScope}
              label={plural(terminology().sendPaymentsContractor)}
            />

            <SideNavMenuItem
              linkTo="/member/1099-filing"
              testId="NEC1099Filing"
              isVisible={
                hasPaymentsScope &&
                featureFlagsQuery.data?.nec1099FilingV2 &&
                isEnterpriseUser
              }
              label="1099 Filing"
            />
          </CollapsibleMenu>

          <CollapsibleMenu
            isVisible={!is1099OnlyRecipient && hasFinancesScope}
            isSideNavOpened={isSideNavOpened}
            icon="card"
            label="Financial Benefits"
            isExpanded={topMenu.includes(
              ExpandableSideNavMenuOptions.FinancialBenefits
            )}
            onClick={() =>
              onExpandMainMenuItem(
                ExpandableSideNavMenuOptions.FinancialBenefits
              )
            }
          >
            <SideNavMenuItem
              linkTo="/member/wallet"
              testId="myFinancesWallet"
              isVisible={!shouldPauseAccountWallet}
              label="Wingspan Wallet"
            />

            <SideNavMenuItem
              linkTo="/member/bookkeeping"
              testId="myFinancesIncomeAndExpenses"
              isVisible={true}
              label="Income & Expenses"
            />

            <SideNavMenuItem
              linkTo="/member/taxes"
              testId="myFinancesTaxWithholdings"
              isVisible={!shouldPauseAccountTaxes}
              label="Tax Withholdings"
            />
          </CollapsibleMenu>

          <CollapsibleMenu
            isVisible={
              !is1099OnlyRecipient && hasAdminScope && !isEnterpriseUser
            }
            isSideNavOpened={isSideNavOpened}
            icon="heart"
            label="Lifestyle Benefits"
            isExpanded={topMenu.includes(
              ExpandableSideNavMenuOptions.LifestyleBenefits
            )}
            onClick={() =>
              onExpandMainMenuItem(
                ExpandableSideNavMenuOptions.LifestyleBenefits
              )
            }
          >
            <SideNavMenuItem
              linkTo="/member/benefits/additional-coverage/all"
              testId="benefits-additional-coverage"
              isVisible={true}
              label="Insurance"
            />

            <SideNavMenuItem
              linkTo="/member/benefits/my-benefits"
              testId="benefits-my-benefits"
              isVisible={true}
              label="Premium Perks"
            />

            <SideNavMenuItem
              linkTo="/member/knowledge-center"
              testId="knowledgeCenter"
              isVisible={true}
              label="Knowledge Center"
              exact={true}
            />
          </CollapsibleMenu>

          <SideNavRootLinkOption
            isVisible={
              !is1099OnlyRecipient &&
              featureFlagsQuery.data?.reports &&
              (hasPaymentsScope || hasCollaboratorsScope)
            }
            to="/member/reports"
            testId="reports"
            label="Reporting"
            icon="report"
            isSideNavOpened={isSideNavOpened}
          />
        </WSFlexBox>
        <WSDivider my="XL" />
        <SideNavRootOption
          onClick={openIntercom}
          label="Contact Support"
          icon="support"
          name="contactSupport"
          isSideNavOpened={isSideNavOpened}
        />
        {!is1099OnlyRecipient && (
          <HeadwayNotification
            isMobile={isMobile}
            isSideNavOpened={isSideNavOpened}
            kind="nav"
          />
        )}

        {/* Data & Integrations */}
        <CollapsibleMenu
          isVisible={hasDocumentsScope || hasPaymentsScope}
          isSideNavOpened={isSideNavOpened}
          icon="data"
          label="Data & Integrations"
          isExpanded={bottomMenu.includes(
            ExpandableSideNavMenuOptions.DataAndIntegrations
          )}
          onClick={() =>
            onExpandMainMenuItem(
              ExpandableSideNavMenuOptions.DataAndIntegrations,
              false
            )
          }
        >
          <SideNavMenuItem
            linkTo="/member/documents"
            testId="resourceHubMyDocuments"
            isVisible={hasDocumentsScope}
            label="Documents"
          />

          <SideNavMenuItem
            linkTo="/member/data/custom-fields"
            testId="navCustomFields"
            isVisible={hasAdminScope && showCustomFieldsNav}
            label="Custom Fields"
          />

          <SideNavMenuItem
            linkTo="/member/imports"
            testId="navBatchImports"
            isVisible={hasPaymentsScope && showBatchImportsNav}
            label="Batch Imports"
          />

          <SideNavMenuItem
            linkTo="/member/settings/integrations"
            testId="settingsIntegrations"
            isVisible={
              hasAdminScope &&
              featureFlagsQuery.data?.qboOnboarding &&
              isEnterpriseUser
            }
            label="Integrations"
          />

          <SideNavMenuItem
            linkTo="/member/settings/tokens"
            testId="navTokens"
            isVisible={hasAdminScope}
            label="Developer"
          />

          <SideNavMenuItem
            linkTo="/member/organization/accounts"
            testId="organizationAccounts"
            isVisible={hasAdminScope && isEnterpriseUser}
            label="Organization Accounts"
          />
        </CollapsibleMenu>
        {/* Settings */}
        <CollapsibleMenu
          isVisible={hasAdminScope || hasPaymentsScope}
          isSideNavOpened={isSideNavOpened}
          icon="settings"
          label="Settings"
          isExpanded={bottomMenu.includes(
            ExpandableSideNavMenuOptions.Settings
          )}
          onClick={() =>
            onExpandMainMenuItem(ExpandableSideNavMenuOptions.Settings, false)
          }
        >
          <SideNavMenuItem
            linkTo="/member/settings/account"
            testId="settingsAccount"
            isVisible={hasAdminScope}
            label="Account & Subscription"
          />

          <SideNavMenuItem
            linkTo="/member/settings/payment-methods"
            testId="settingsPaymentMethods"
            isVisible={hasPaymentsScope && showPayoutNav}
            label={"Payout Method"}
          />
          {/* else */}
          <SideNavMenuItem
            linkTo="/member/settings/payment-methods"
            testId="settingsPaymentMethods"
            isVisible={hasPaymentsScope && showPaymentNav}
            label={"Payment Methods"}
          />
          {/* else */}
          <SideNavMenuItem
            linkTo="/member/settings/payment-methods"
            testId="settingsPaymentMethods"
            isVisible={hasPaymentsScope && !showPayoutNav && !showPaymentNav}
            label={"Payout & Payment Methods"}
          />

          <SideNavMenuItem
            linkTo="/member/settings/personal-info"
            testId="settingsPersonalInfo"
            isVisible={hasAdminScope}
            label="Personal Info"
          />

          <SideNavMenuItem
            linkTo="/member/settings/business-info"
            testId="settingsBusinessInfo"
            isVisible={hasAdminScope}
            label="Business Info"
          />

          <SideNavMenuItem
            linkTo="/member/settings/billing/payables"
            testId="settingsPayroll"
            isVisible={
              (hasPaymentsScope || hasAdminScope) &&
              showPayeableAndPayrollSettingsNav
            }
            label="Payable & Payroll Settings"
          />

          <SideNavMenuItem
            linkTo={ENGAGEMENT_REQUIREMENTS_SETTINGS_ROOT_PATH}
            testId="settingsEngagementRequirements"
            isVisible={hasAdminScope && isEngagementsActive}
            label="Engagement Requirements"
          />

          <SideNavMenuItem
            linkTo="/member/settings/tax-profile"
            testId="settingsTaxProfile"
            isVisible={hasAdminScope}
            label="Tax Profile"
          />

          <SideNavMenuItem
            linkTo="/member/tax-documents"
            testId="settingsTaxDocuments"
            isVisible={isPayeeOnTaxForms && hasAdminScope}
            label="Tax Documents"
          />

          <SideNavMenuItem
            linkTo="/member/settings/notifications"
            testId="settingsNotifications"
            isVisible={hasAdminScope}
            label="Notifications"
          />

          <SideNavMenuItem
            linkTo="/member/settings/team"
            testId="settingsTeam"
            isVisible={hasAdminScope}
            label="Team"
          />
          <SideNavMenuItem
            linkTo="/member/settings/email-settings"
            testId="settingsEmail"
            isVisible={hasAdminScope && hasEmailSnippets}
            label="Email settings"
          />
        </CollapsibleMenu>
        <WSFlexBox
          direction="column"
          className={styles.bottomNavItems}
          wrap="nowrap"
        >
          <SideNavRootLinkOption
            isVisible={true}
            to="/sign-out"
            testId="logout"
            label="Log out"
            icon="logout"
            isSideNavOpened={isSideNavOpened}
          />
        </WSFlexBox>

        <WSScreen.Mobile>
          {user.data?.tag ? (
            <WSFlexBox.Center className={styles.copyLinkWrapper}>
              <WSElement
                as="button"
                className={styles.copyLink}
                px="3XL"
                py="M"
                onClick={async () => {
                  await copyText(createPPL(user.data?.tag as string));

                  openSnackbar({
                    type: "success",
                    message: "Copied",
                    duration: 3000
                  });
                }}
              >
                {truncate(createPPL(user.data?.tag), { length: 25 })}
              </WSElement>
            </WSFlexBox.Center>
          ) : null}
        </WSScreen.Mobile>
      </WSFlexBox>
    </WSElement>
  );
};

export interface SideNavProps extends WSElementProps {
  isDrawerOpened?: boolean;
  setIsDrawerOpened?: (isDrawerOpened: boolean) => void;
  isSideNavOpened?: boolean;
  setIsSideNavOpened?: (isSideNavOpened: boolean) => void;
  desktopTopPadding?: number;
}

export const SideNav: React.FC<SideNavProps> = ({
  isDrawerOpened,
  setIsDrawerOpened = () => {},
  isSideNavOpened,
  setIsSideNavOpened,
  desktopTopPadding
}) => {
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  const handleTouchStart: TouchEventHandler<HTMLElement> = event => {
    const clientX = event.targetTouches[0].clientX;

    setTouchStart(clientX);
    setTouchEnd(clientX);
  };

  const handleTouchMove: TouchEventHandler<HTMLElement> = event => {
    const clientX = event.targetTouches[0].clientX;

    setTouchEnd(clientX);
  };

  const handleTouchEnd: TouchEventHandler<HTMLElement> = () => {
    if (touchStart - touchEnd > 100) {
      setIsDrawerOpened(false);
    }
  };

  return (
    <>
      <WSScreen.Mobile>
        <WSElement
          onTouchEnd={handleTouchEnd}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onClick={event => {
            const target = event.target as HTMLElement;

            if (target === event.currentTarget) {
              setIsDrawerOpened(false);
            }

            if (target.tagName.toLowerCase() === "a" || target.closest("a")) {
              setIsDrawerOpened(false);
            }
          }}
          className={classNames(styles.mobileNavContainer, {
            [styles.mobileNavOpened]: isDrawerOpened
          })}
        >
          <SideNavMenu setIsDrawerOpened={setIsDrawerOpened} />
        </WSElement>
      </WSScreen.Mobile>
      <WSScreen.TabletAndDesktop>
        <WSElement
          className={styles.desktopNavContainer}
          style={{ top: desktopTopPadding }}
        >
          <SideNavMenu
            isSideNavOpened={isSideNavOpened}
            setIsSideNavOpened={setIsSideNavOpened}
          />
        </WSElement>
      </WSScreen.TabletAndDesktop>
    </>
  );
};
