import {
  WSAvatar,
  WSCopyText,
  WSElement,
  WSElementProps,
  WSFlexBox,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import {
  InvoicePaymentType,
  IPayerResponse,
  PaymentRequirementStrategy
} from "@wingspanhq/payments/dist/interfaces";

import { wsName } from "@wingspanhq/utils/dist/name/wsName";
import { useMemo } from "react";
import { getAutopayLink } from "../../../../Invoices/utils";
import { useUserId } from "../../../../query/hooks/helpers";
import {
  useMemberProfile,
  useUserProfile
} from "../../../../query/users/queries";
import { FieldView } from "../../../Payees/components/FieldView";
import styles from "./styles.module.scss";

export interface AutoPayProps extends WSElementProps {
  client: IPayerResponse;
}

export const AutoPay: React.FC<AutoPayProps> = ({
  client,
  ...elementProps
}) => {
  const userId = useUserId();
  const queryUser = useUserProfile(userId);
  const queryMember = useMemberProfile(userId);
  const isAutopayOn =
    client.payerOwnedData?.autoPayStrategy === PaymentRequirementStrategy.All;

  const autopayLink = useMemo(() => {
    const payerEmail = client.user?.email;
    const payeeTag = queryUser.data?.tag;
    const payeeCompanyName =
      !!queryUser.data &&
      wsName({
        user: queryUser.data,
        member: queryMember.data
      }).companyName;

    if (!payerEmail || !payeeTag || !payeeCompanyName) {
      return;
    }

    return getAutopayLink({
      tag: payeeTag,
      companyName: payeeCompanyName,
      payeeId: userId,
      email: payerEmail
    });
  }, [client.user?.email, queryMember.data, queryUser.data, userId]);

  return (
    <WSElement {...elementProps}>
      <WSSectionToolbar
        title="Autopay"
        pill={{
          theme: isAutopayOn ? "success" : "neutral",
          text: isAutopayOn ? "On" : "Off",
          badge: true
        }}
      />

      <WSPanel className={styles.panel}>
        <WSText.ParagraphSm weight="book" mb="XL">
          {isAutopayOn
            ? "Each invoice you send will be automatically paid on the due date unless this client disables Autopay."
            : "To set up Autopay, share this link with your client and they can set up automatic payments."}
        </WSText.ParagraphSm>

        {isAutopayOn ? (
          <FieldView
            label="Payment Method"
            value={
              client.payerPaymentMethod ? (
                <WSFlexBox.CenterY gap="S">
                  <WSAvatar.Icon
                    icon={
                      client.payerPaymentMethod.sourceType ===
                      InvoicePaymentType.Card
                        ? "card"
                        : "bank"
                    }
                    size="M"
                    color="gray500"
                  />
                  <WSText.ParagraphSm weight="book" color="gray600">
                    {client.payerPaymentMethod.sourceMetadata.institution} (
                    {client.payerPaymentMethod.sourceMetadata.mask})
                  </WSText.ParagraphSm>
                </WSFlexBox.CenterY>
              ) : (
                "None"
              )
            }
          />
        ) : null}

        {autopayLink && (
          <WSCopyText
            mt="XL"
            isURL
            shareTitle="Autopay link"
            data={autopayLink}
          />
        )}
      </WSPanel>
    </WSElement>
  );
};
