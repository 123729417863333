import { WSAlert } from "@wingspanhq/fe-component-library";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQueryVerifications } from "../../../query/onboarding/queries/useQueryVerifications";
import { verificationService } from "../../../shared/services/verificaiton";

export const BannerBankingVerification: React.FC = () => {
  const history = useHistory();
  const queryVerifications = useQueryVerifications();
  const [loading, setLoading] = useState(false);

  if (queryVerifications.isLoading) {
    return null;
  }

  const handleAdditionalDataFlow = async () => {
    setLoading(true);
    await verificationService.completeBankingVerification(history);
    setLoading(false);
  };

  return !queryVerifications.data ||
    queryVerifications.data?.banking === "None" ||
    queryVerifications.data?.banking === "UpdateRequired" ? (
    <WSAlert
      theme="warning"
      icon="alert"
      title="Additional information needed for banking verification"
      action={{
        loading,
        text: "Complete banking verification",
        onClick: handleAdditionalDataFlow
      }}
    >
      Additional information is required to use Wingspan Wallet or Tax
      Withholding, per banking regulations. Banking verification must be
      complete to start using banking features.
    </WSAlert>
  ) : queryVerifications.data?.banking === "Pending" ||
    queryVerifications.data?.banking === "Failed" ? (
    <WSAlert
      theme="info"
      icon="info-circle"
      title="Banking verification in progress"
    >
      Your information was received and our team is currently reviewing it. Once
      the information is approved, features such as Wingspan Wallet and Tax
      Withholdings can be activated. The review process typically takes less
      than one (1) business day. Thank you for your patience.
    </WSAlert>
  ) : null;
};
