import {
  WSActions,
  WSControl,
  WSElement,
  WSForm,
  WSInfoBox,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import { useMemo } from "react";
import * as Yup from "yup";
import { WSErrorMessage } from "../../../../components/WSErrorMessage/WSErrorMessage";
import { components } from "../../../../services/api/banking/types";
import {
  bankingAccountAcknowledgements,
  internationalClearingAccountAcknowledgements,
  taxWithholdingAccountAcknowledgements
} from "../../../../shared/constants/acknowledgements";
import { Acknowledgements } from "../Acknowledgements";
import { useMutationSubmit } from "./useMutationSubmit";

type Props = {
  type: components["schemas"]["InternalAccountCreate"]["type"];
  currency: components["schemas"]["InternalAccountCreate"]["currency"];
  setFundingSource?: boolean;
  setStandardPayoutDestination?: boolean;
  onBack?: () => void;
  onContinue?: () => void;
};

export const FormCreateInternalAccount: React.FC<Props> = ({
  type,
  currency,
  setFundingSource,
  setStandardPayoutDestination,
  onBack,
  onContinue
}) => {
  const acknowledgements = useMemo(() => {
    switch (type) {
      case "Banking":
        return bankingAccountAcknowledgements;
      case "TaxWithholding":
        return taxWithholdingAccountAcknowledgements;
      case "InternationalClearing":
        return internationalClearingAccountAcknowledgements;
      default:
        return [];
    }
  }, [type]);

  const [submit, meta] = useMutationSubmit(
    type,
    currency,
    acknowledgements,
    { setFundingSource, setStandardPayoutDestination },
    { onSuccess: onContinue }
  );

  return (
    <WSForm
      validationSchema={Yup.object().shape({
        agreement: Yup.boolean()
          .nullable()
          .required("Required")
      })}
      onSubmit={submit}
    >
      <WSList gap="2XL">
        <WSPanel>
          <WSList gap="2XL">
            <WSElement>
              <WSSectionToolbar title="Activate Wingspan banking features" />
              <WSText.ParagraphSm color="gray500">
                You’ve opted to activate a Wingspan banking feature. Review and
                agree to all the agreements and terms below to continue.
              </WSText.ParagraphSm>
            </WSElement>

            <WSInfoBox>
              By clicking continue you agree that you have read, understood, and
              agree to the following:
              <br />
              <br />
              <Acknowledgements aknowledgements={acknowledgements} />
              <br />
              Wingspan is not a financial services technology company and is not
              a bank. Banking services provided by Lead Bank, member FDIC.
            </WSInfoBox>

            <WSForm.Field
              name="agreement"
              component={WSControl}
              componentProps={{
                type: "checkbox",
                label:
                  "I have read, and agree to all the above-mentioned documents and their contents.",
                size: "S"
              }}
            />

            <WSErrorMessage contextKey="InternalAccount" error={meta.error} />
          </WSList>
        </WSPanel>

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              kind: "Secondary",
              onClick: onBack,
              visible: !!onBack,
              type: "button"
            },
            {
              label: "Continue",
              loading: meta.isLoading
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
