import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces/api/notifications";
import React from "react";
import { useHistory } from "react-router-dom";
import { BankingFailed } from "./templates/BankingFailed";
import { BankingPending } from "./templates/BankingPending";
import { BankingUpdateRequired } from "./templates/BankingUpdateRequired";
import { BusinessInfo } from "./templates/BusinessInfo";
import { Collaborators } from "./templates/Collaborators";
import { PayoutsInfo } from "./templates/PayoutsInfo";
import { Payroll } from "./templates/Payroll";
import { SignatureClient } from "./templates/SignatureClient";
import { SignatureCollaborator } from "./templates/SignatureCollaborator";
import { FileSharingRequestContractor } from "./templates/FileSharingRequestContractor";
import { TINVerificationPending } from "./templates/TINVerificationPending";
import { TaxFailed } from "./templates/TaxFailed";
import { TaxPending } from "./templates/TaxPending";
import { TaxUpdateRequired } from "./templates/TaxUpdateRequired";
import { UnitVerification } from "./templates/UnitVerification";
import { UnitVerificationPending } from "./templates/UnitVerificationPending";
import { W8BENCertification } from "./templates/W8BENCertification";
import { W9Certification } from "./templates/W9Certification";
import { W9Consent } from "./templates/W9Consent";
import { W9InfoCollaborator } from "./templates/W9InfoCollaborator";

export const getClientNameFromContext = (
  context:
    | {
        client: { companyName: string };
      }
    | any
) => {
  return context?.client?.companyName || "your client";
};
export const getMemberNameFromContext = (
  context:
    | {
        member: { firstName: string; lastName: string };
      }
    | any
) => {
  return (
    `${context?.member?.firstName} ${context?.member?.lastName}`.trim() ||
    "your client"
  );
};

export const getDocumentNameFromContext = (
  context:
    | {
        document: { title: string };
      }
    | any
) => {
  return context?.document?.title || "your document";
};

export enum NotificationHandleType {
  W9InfoCollaborator = "W9InfoCollaborator",
  // W9InfoUpdate = "W9InfoUpdate",
  W9Consent = "W9Consent",
  W9Certification = "W9Certification",
  W8BENCertification = "W8BENCertification",
  TINVerification = "TINVerification",
  TINVerificationPending = "TINVerificationPending",
  PayoutsInfo = "PayoutsInfo",
  // PayoutsInfoUpdate = "PayoutsInfoUpdate",
  SignatureCollaborator = "SignatureCollaborator",
  FileSharingRequestContractor = "FileSharingRequestContractor",
  UnitVerification = "UnitVerification",
  UnitVerificationPending = "UnitVerificationPending",
  BusinessInfo = "BusinessInfo",
  Collaborators = "Collaborators",
  Payroll = "Payroll",
  SignatureClient = "SignatureClient",

  TaxPending = "TaxPending",
  TaxVerified = "TaxVerified",
  TaxUpdateRequired = "TaxUpdateRequired",
  TaxFailed = "TaxFailed",

  BankingPending = "BankingPending",
  BankingVerified = "BankingVerified",
  BankingUpdateRequired = "BankingUpdateRequired",
  BankingFailed = "BankingFailed"
}

export type NotificationProps<C> = Omit<
  IChannelNotificationResponse,
  "context"
> & {
  context: C;
  group: IChannelNotificationResponse[];
};

export type NotificationTemplate<C = any> = {
  isPending?: boolean;
  renderTitle: (props: NotificationProps<C>) => string;
  renderBody: (props: NotificationProps<C>) => React.ReactNode;
  Footer?: React.FC<NotificationProps<C>>;
  buttonText?: (props: NotificationProps<C>) => string;
  buttonAction?: (
    props: NotificationProps<C>,
    history: ReturnType<typeof useHistory>
  ) => () => void;
  buttonActionAsync?: (
    props: NotificationProps<C>,
    history: ReturnType<typeof useHistory>
  ) => () => Promise<void>;
};

export const NotificationTemplates = {
  [NotificationHandleType.BusinessInfo]: BusinessInfo,
  [NotificationHandleType.Collaborators]: Collaborators,
  [NotificationHandleType.Payroll]: Payroll,
  [NotificationHandleType.SignatureCollaborator]: SignatureCollaborator,
  [NotificationHandleType.FileSharingRequestContractor]: FileSharingRequestContractor,
  [NotificationHandleType.SignatureClient]: SignatureClient,
  [NotificationHandleType.PayoutsInfo]: PayoutsInfo,
  [NotificationHandleType.TINVerificationPending]: TINVerificationPending,
  [NotificationHandleType.UnitVerification]: UnitVerification,
  [NotificationHandleType.UnitVerificationPending]: UnitVerificationPending,
  [NotificationHandleType.W8BENCertification]: W8BENCertification,
  [NotificationHandleType.W9Certification]: W9Certification,
  [NotificationHandleType.W9Consent]: W9Consent,
  [NotificationHandleType.W9InfoCollaborator]: W9InfoCollaborator,

  [NotificationHandleType.TaxPending]: TaxPending,
  [NotificationHandleType.TaxUpdateRequired]: TaxUpdateRequired,
  [NotificationHandleType.TaxFailed]: TaxFailed,
  [NotificationHandleType.BankingPending]: BankingPending,
  [NotificationHandleType.BankingUpdateRequired]: BankingUpdateRequired,
  [NotificationHandleType.BankingFailed]: BankingFailed
};
