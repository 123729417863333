import * as Yup from "yup";
import { signatureValidationSchema } from "../../modules/Signature/createRequirementDefinition/signatureValidationSchema";
import { sharedFileValidationSchema } from "../../modules/SharedFile/createRequirementDefinition/sharedFileValidationSchema";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

const baseValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.string()
    .oneOf([RequirementType.Signature, RequirementType.SharedFile])
    .required("Type is required"),
  description: Yup.string()
});

export const validationSchema = baseValidationSchema
  .concat(signatureValidationSchema)
  .concat(sharedFileValidationSchema);
