export const clearObjectEmptyKeys = (obj: any): any => {
  if ("File" in window && obj instanceof File) {
    return obj;
  }

  if ("Blob" in window && obj instanceof Blob) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj
      .map(item => clearObjectEmptyKeys(item))
      .filter(
        item =>
          !(
            item === undefined ||
            item === null ||
            item === "" ||
            (typeof item === "object" && Object.keys(item).length === 0)
          )
      );
  } else if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const cleanedValue = clearObjectEmptyKeys(value);

      if (
        cleanedValue === undefined ||
        cleanedValue === null ||
        cleanedValue === ""
      ) {
        return acc;
      }

      if (
        typeof cleanedValue === "object" &&
        Object.keys(cleanedValue).length === 0
      ) {
        return acc;
      }

      return { ...acc, [key]: cleanedValue };
    }, {});
  }

  return obj;
};
