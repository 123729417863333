import { toWSDateString } from "@wingspanhq/fe-component-library";
import { selectorCountryName } from "../../../shared/selectors/selectorCountryName";
import { Section } from "./Section";
import { getTaxIdLabel } from "../utils/taxId";
import {
  FormPartialDataPerson,
  FormPartialPersonType
} from "./FormPartialPerson";
import { getLast4 } from "../../../shared/utils/getLast4";
import { formatPhone } from "../../../shared/utils/formatPhone";

type Props = {
  title: string;
  data: FormPartialDataPerson;
  onEdit?: () => void;
  type: FormPartialPersonType;
  showOwnershipStake?: boolean;
};

export const ReviewPerson: React.FC<Props> = ({
  title,
  data,
  onEdit,
  type,
  showOwnershipStake
}) => (
  <Section
    title={title}
    onEdit={onEdit}
    data={[
      {
        label: "Legal first name",
        value: data.firstName,
        fsExclude: true
      },
      {
        label: "Middle name",
        value: data.middleName,
        fsExclude: true
      },
      {
        label: "Legal last name",
        value: data.lastName,
        fsExclude: true
      },
      {
        label: "Job title / occupation",
        value: data.jobTitle
      },
      showOwnershipStake
        ? {
            label: "Ownership stake",
            value: data.ownershipStake ? `${data.ownershipStake}%` : null
          }
        : null,
      {
        label: "Birthday",
        value: data.birthday
          ? toWSDateString(data.birthday, "monthDayYear")
          : null
      },
      type === "BeneficialOwner"
        ? {
            label: "Email",
            value: data.email
          }
        : null,
      type === "BeneficialOwner"
        ? {
            label: "Phone",
            value: formatPhone(
              `+${data.phone.countryCode}${data.phone.phoneNumber}`
            )
          }
        : null,
      {
        label: "Country",
        value: data.country ? selectorCountryName(data.country) : null
      },
      {
        label: getTaxIdLabel("Individual", data.country),
        value:
          typeof data.taxId === "string" && data.taxId
            ? `••• ${getLast4(data.taxId)}`
            : data.taxId,
        fsExclude: true
      },
      {
        label: "Residential address",
        value: [
          data.address.addressLine1,
          data.address.addressLine2,
          [data.address.city, data.address.state, data.address.postalCode].join(
            " "
          )
        ]
          .filter(Boolean)
          .join("\n"),
        fsExclude: true
      }
    ]}
  />
);
