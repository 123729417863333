import {
  WSActions,
  WSDivider,
  WSForm,
  WSFormProps,
  WSList,
  WSPanel,
  WSSectionToolbar,
  WSText
} from "@wingspanhq/fe-component-library";
import merge from "lodash/merge";
import { WSFormErrorSummary } from "../../../../shared/components/WSFormErrorSummary";
import { WS_LINKS } from "../../../../types/wsLinks";
import { useModalWhyWeCollectInfo } from "../ModalWhyWeCollectInfo";
import { FormPartialDataPerson, FormPartialPerson } from "../FormPartialPerson";
import { emptyValues } from "./emptyValues";
import { getValidationSchema } from "./validationSchema";

export type FormData = { person: FormPartialDataPerson };

type Props = {
  defaultValues?: WSFormProps<FormData>["defaultValues"];
  onSubmit: WSFormProps<FormData>["onSubmit"];
  country: string;
  onBack?: () => void;
};

export const FormPersonalInformation: React.FC<Props> = ({
  defaultValues,
  country,
  onBack,
  onSubmit
}) => {
  const modal = useModalWhyWeCollectInfo();

  return (
    <WSForm<FormData>
      defaultValues={merge(emptyValues, defaultValues)}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
    >
      <WSList gap="2XL" mb="2XL">
        <WSPanel>
          <WSSectionToolbar
            title="Personal information"
            button={{
              label: "Why do we collect this info?",
              onClick: modal.open
            }}
          />
          <WSText.ParagraphSm color="gray500">
            Wingspan is required to collect this information for compliance
            purposes. Your information is{" "}
            <a href={WS_LINKS.security} target="_blank" rel="noreferrer">
              certified secure
            </a>
            .
          </WSText.ParagraphSm>

          <WSDivider my="L" />

          <FormPartialPerson type="Individual" name="person" requireTaxId />
        </WSPanel>
        <WSFormErrorSummary />

        <WSActions
          alignment="fill"
          buttons={[
            {
              label: "Back",
              onClick: onBack,
              kind: "Secondary",
              type: "button",
              visible: !!onBack
            },
            {
              label: "Continue"
            }
          ]}
        />
      </WSList>
    </WSForm>
  );
};
