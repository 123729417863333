import { IChannelNotificationResponse } from "@wingspanhq/notifications/dist/lib/interfaces";
import { selectorActiveNotifications } from "./selectorActiveNotifications";

import { NotificationHandleType } from "../components/Notifications";

type NotificationWithGroup = IChannelNotificationResponse & {
  group: IChannelNotificationResponse[];
};

export const selectorGroupedVisibleNotifications = (
  responses: IChannelNotificationResponse[]
) => {
  const result: NotificationWithGroup[] = [];

  selectorActiveNotifications(responses).forEach(response => {
    if (
      [
        NotificationHandleType.SignatureCollaborator,
        NotificationHandleType.FileSharingRequestContractor,
        NotificationHandleType.SignatureClient,
        NotificationHandleType.W9Consent
      ].includes(response.handle as NotificationHandleType) &&
      result.some(r => r.handle === response.handle)
    ) {
      result.find(r => r.handle === response.handle)?.group.push(response);
    } else {
      result.push({ ...response, group: [response] });
    }
  });

  return result;
};
