import {
  WSButton,
  WSCentered,
  WSCopyButton,
  WSElement,
  WSFlexBox,
  WSIcon,
  WSLayout,
  WSPanel,
  WSText
} from "@wingspanhq/fe-component-library";
import { InvoiceStatus, IInvoice } from "@wingspanhq/payments/dist/interfaces";
import { MemberWithholdingStatus } from "@wingspanhq/users/dist/lib/interfaces";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Card } from "../../ClientPayments/components/Card/Card";
import { addNotification } from "../../components/Notification/Notification";
import { WSQueries } from "../../query/WSQuery";
import { useUserId } from "../../query/hooks/helpers";
import { useDownloadInvoicePdf } from "../../query/payments/mutations";
import {
  useInvoiceQuery,
  useMemberClientQuery
} from "../../query/payments/queries";
import { getClientCompany } from "../../query/payments/selectors";
import { useMemberProfile } from "../../query/users/queries";
import { FirstInvoiceBanner } from "../components/FirstInvoiceBanner/FirstInvoiceBanner";
import {
  InvoiceStatusTimeline,
  InvoiceTimelineStatus
} from "../components/InvoiceStatusTimeline/InvoiceStatusTimeline";
import { InvoiceTotals } from "../components/InvoiceTotals/InvoiceTotals";
import { useInvoiceFormGoBack } from "../utils/invoicesFormUtils";
import styles from "./InvoicesInvoiceConfirmation.module.scss";
import { useShouldPauseAccount } from "../../shared/hooks/useShouldPauseAccount";
import { openIntercom } from "../../shared/utils/intercom";

export const InvoicesInvoiceConfirmation: React.FC<RouteComponentProps<{
  invoiceId: string;
}>> = ({
  match: {
    params: { invoiceId }
  }
}) => {
  const history = useHistory();
  const userId = useUserId();
  const memberQuery = useMemberProfile(userId);
  const invoiceQuery = useInvoiceQuery(invoiceId);
  const memberClientQuery = useMemberClientQuery(
    invoiceQuery.data?.memberClientId as string,
    {
      retry: false,
      enabled: !!invoiceQuery.data?.memberClientId
    }
  );
  const shouldPauseAccount = useShouldPauseAccount("taxes");
  const [downloadInvoicePdf, downloadInvoicePdfMeta] = useDownloadInvoicePdf();
  const goBack = useInvoiceFormGoBack();

  function moveToClientDetails(invoice: IInvoice) {
    const clientDetailsPath = 
      invoice.memberClientId
        ? `/member/invoices/${invoiceId}/edit/clients/${invoice.memberClientId}`
        : undefined;

    if (clientDetailsPath) {
      history.push({
        pathname: clientDetailsPath
      });
    }
  }

  return (
    <WSLayout
      headerLeft={
        <WSIcon
          block
          data-testid="backButton"
          name="arrow-left"
          className={styles.backIcon}
          onClick={goBack}
        />
      }
      headerCenter={
        <WSQueries
          queries={{
            invoiceQuery
          }}
        >
          {({ invoiceQuery: { data: invoice } }) => (
            <WSText weight="medium">Invoice #{invoice.invoiceNumber}</WSText>
          )}
        </WSQueries>
      }
      line
    >
      <WSElement className={styles.main} p="M">
        <WSCentered span={{ m: "4" }}>
          <WSQueries
            queries={{
              memberQuery,
              invoiceQuery,
              memberClientQuery
            }}
          >
            {({
              memberQuery: { data: member },
              invoiceQuery: { data: invoice },
              memberClientQuery: { data: memberClient }
            }) => {
              const taxWithholdingsEnabled =
                member.profile.withholdings?.tax?.status ===
                MemberWithholdingStatus.Active;

              return (
                <>
                  <FirstInvoiceBanner mb="XL" />

                  <Card mb="XL">
                    <WSFlexBox justify="space-between" wrap="nowrap">
                      <WSText.Heading4 mb="M">
                        {getClientCompany(memberClient)}
                      </WSText.Heading4>
                      <WSButton.Link
                        onClick={() => {
                          downloadInvoicePdf({
                            invoiceId: invoice.invoiceId,
                            status: invoice.status
                          });
                        }}
                        loading={downloadInvoicePdfMeta.isLoading}
                      >
                        Download PDF
                      </WSButton.Link>
                    </WSFlexBox>

                    <WSText.Heading5 mb="XS">
                      {invoice.status === InvoiceStatus.Pending
                        ? "Invoice is pending"
                        : invoice.notificationPreferences?.sendInvoice
                        ? "Your invoice has been sent"
                        : "Your invoice has been opened"}
                    </WSText.Heading5>
                    <WSText color="gray500" mb="XL">
                      <WSText>
                        {invoice.notificationPreferences?.sendInvoice ? (
                          <WSText>
                            {invoice.status === InvoiceStatus.Pending
                              ? "Will be sent to"
                              : "Sent to"}{" "}
                            <WSButton.Link onClick={() => { moveToClientDetails(invoice) }}>
                              {memberClient.emailTo}
                            </WSButton.Link>
                          </WSText>
                        ) : (
                          "Use your invoice link to send your payment requests directly to clients through your existing communication channel."
                        )}
                      </WSText>
                      {invoice.status === InvoiceStatus.Pending && (
                        <WSButton.Link my="M" onClick={openIntercom}>
                          Ask about pending invoices
                        </WSButton.Link>
                      )}
                    </WSText>
                    <InvoiceStatusTimeline
                      currentStatus={
                        invoice.status === InvoiceStatus.Pending
                          ? undefined
                          : "Sent"
                      }
                      statuses={[
                        InvoiceTimelineStatus.Sent,
                        InvoiceTimelineStatus.Viewed,
                        InvoiceTimelineStatus.Paid,
                        InvoiceTimelineStatus.Deposited
                      ]}
                      mb="XL"
                    />
                    <WSCopyButton
                      fullWidth
                      mr="M"
                      className={styles.mainButton}
                      name="copyInvoiceLink"
                      onCopy={() => {
                        addNotification({
                          text: "Invoice link copied to clipboard."
                        });
                      }}
                      data={invoice.attachments?.invoiceLink as string}
                      kind="Secondary"
                      isURL
                      copyLabel="Copy invoice link"
                      shareLabel="Share invoice link"
                      shareTitle="Invoice link"
                      {...{
                        "data-test-copy-text": invoice.attachments?.invoiceLink
                      }}
                    />
                  </Card>

                  <Card mb="XL">
                    <WSText.Heading5 mb="M" data-testid="paymentTimingText">
                      Payment timing
                    </WSText.Heading5>
                    <WSText color="gray600">
                      Funds are automatically deposited within 7 business days
                      of payment.
                    </WSText>
                  </Card>

                  <Card>
                    <WSText.Heading5 mb="M">Summary</WSText.Heading5>

                    <InvoiceTotals mb="XL" invoiceId={invoiceId} />

                    {!shouldPauseAccount && !taxWithholdingsEnabled && (
                      <WSPanel className={styles.enableWithholdingsPanel}>
                        <WSFlexBox.CenterY mb="M">
                          <WSIcon
                            block
                            name="alert-circle"
                            color="amber400"
                            mr="M"
                          />
                          <WSText weight="medium">
                            Tax withholdings is not enabled
                          </WSText>
                        </WSFlexBox.CenterY>
                        <WSText.ParagraphXs color="gray600" mb="XL">
                          Enable tax withholdings today to ensure you’re setting
                          aside enough for {new Date().getFullYear()} taxes.
                        </WSText.ParagraphXs>
                        <WSButton
                          size="S"
                          fullWidth
                          className={styles.withholdingsButton}
                          onClick={() => {
                            history.push("/member/taxes");
                          }}
                        >
                          Learn more
                        </WSButton>
                      </WSPanel>
                    )}

                    <WSButton
                      mt="2XL"
                      onClick={() => {
                        history.push("/member/invoices");
                      }}
                      fullWidth
                    >
                      Back to invoices
                    </WSButton>
                  </Card>
                </>
              );
            }}
          </WSQueries>
        </WSCentered>
      </WSElement>
    </WSLayout>
  );
};
