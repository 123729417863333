import { useWSSnackbar } from "@wingspanhq/fe-component-library";
import { IAccount } from "@wingspanhq/users/dist/lib/interfaces";
import { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import plaidIcon from "../../../../assets/images/plaid-icon.png";
import { useQueryCustomerEntity } from "../../../../query/onboarding/queries/useQueryCustomerEntity";
import { FormAddDebitCard } from "../../../../shared/components/FormAddDebitCard";
import { FormManuallyAddAccount } from "../../../../shared/components/FormManuallyAddAccount";
import { FormManuallyAddInternationalAccount } from "../../../../shared/components/FormManuallyAddInternationalAccount";
import {
  avatarBankAccount,
  avatarBankCard,
  avatarInstantPayout,
  avatarInternationalBankAccount,
  avatarPlaid,
  avatarUSBankAccount,
  avatarWallet
} from "../../../../shared/constants/avatars";
import { useWSPlaidLinkExtended } from "../../../../utils/useWSPlaidLinkExtended";
import { selectorAccountRtpReady } from "../../../Transfer/components/FormTransfer/selectorAccountRtpReady";
import { selectorAccountWithdrawalReady } from "../../../Transfer/components/FormTransfer/selectorAccountWithdrawalReady";
import { FormCreateInternalAccount } from "../FormCreateInternalAccount";
import { FormSelectAccount } from "../FormSelectAccount";
import { StepOptions } from "../Options";
import {
  PayoutMethodInstantType,
  PayoutMethodStandardType,
  PayoutMethodType
} from "./types";

type Props = { basePath: string; onSuccess?: () => void };

export const FlowAddPayoutMethod: React.FC<Props> = ({
  basePath,
  onSuccess
}) => {
  const queryCustomerEntity = useQueryCustomerEntity();
  const isInternational =
    queryCustomerEntity.data?.country &&
    queryCustomerEntity.data?.country !== "US";

  const history = useHistory();
  const [createdAccounts, setCreatedAccounts] = useState<IAccount[]>([]);

  const { openSnackbar } = useWSSnackbar();

  const instantPlaidLink = useWSPlaidLinkExtended({
    onSuccess: accounts => {
      const filteredAccounts = accounts.filter(selectorAccountRtpReady);

      if (filteredAccounts.length > 0) {
        setCreatedAccounts(accounts);

        history.push(basePath + "/instant-plaid");
      } else {
        openSnackbar({
          type: "error",
          message: "Account can not be used as a payout method"
        });
      }
    }
  });

  const standardPlaidLink = useWSPlaidLinkExtended({
    onSuccess: accounts => {
      const filteredAccounts = accounts.filter(selectorAccountWithdrawalReady);

      if (filteredAccounts.length > 0) {
        setCreatedAccounts(accounts);

        history.push(basePath + "/standard-plaid");
      } else {
        openSnackbar({
          type: "error",
          message: "Account can not be used as a payout method"
        });
      }
    }
  });

  return (
    <Switch>
      <Route path={basePath + "/type"}>
        <StepOptions<PayoutMethodType>
          key="type"
          title="Add payout method"
          description="Add a payout method to deposit your income"
          options={
            isInternational
              ? [
                  {
                    label: "Non-U.S. account",
                    helperText:
                      "Wingspan will deposit into your bank account in the currency of that account in a non-U.S. country or in U.S.-dollars for USD accounts.",
                    value: "international",
                    avatar: avatarInternationalBankAccount
                  },
                  {
                    label: "U.S. based account",
                    helperText:
                      "Wingspan will keep your deposits in USD and deposit it in your U.S.-based account.",
                    value: "standard",
                    avatar: avatarUSBankAccount
                  }
                ]
              : [
                  {
                    label: "Instant to debit card (1% fee)",
                    helperText:
                      "Fastest. Receive your payments immediately when sent.",
                    value: "instant",
                    avatar: avatarInstantPayout
                  },
                  {
                    label: "Wingspan Wallet (free)",
                    helperText: "Faster. Free business bank & debit card.",
                    value: "wallet",
                    avatar: avatarWallet
                  },
                  {
                    label: "Bank account (free)",
                    helperText: "Standard. Connect your existing bank account.",
                    value: "standard",
                    avatar: avatarBankAccount
                  }
                ]
          }
          onContinue={value => {
            history.push(basePath + "/" + value);
          }}
        />
      </Route>

      <Route path={basePath + "/instant"}>
        <StepOptions<PayoutMethodInstantType>
          key="instant"
          title="Add instant payout method (1% fee)"
          description="Receive your funds in under an hour. A 1% fee will be applied when the transaction is made."
          options={[
            {
              label: "Connect a debit card",
              helperText:
                "Receive payments by connecting an eligible Visa or Mastercard debit card",
              value: "instant-card",
              avatar: avatarBankCard
            },
            {
              label: "Set up direct deposit with Plaid",
              helperText:
                "Instantly connect your bank accounts securely (Instant verification)",
              value: "instant-plaid",
              avatar: avatarPlaid
            },
            {
              label: "Set up direct deposit manually",
              helperText: "Manually enter your routing and account numbers",
              value: "instant-manual",
              avatar: avatarBankAccount
            }
          ]}
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onContinue={value => {
            if (value === "instant-plaid") {
              instantPlaidLink.open();
            } else {
              history.push(basePath + "/" + value);
            }
          }}
        />
      </Route>

      <Route path={basePath + "/standard"}>
        <StepOptions<PayoutMethodStandardType>
          key="stanrard"
          title="Add bank account (free)"
          description="Add a bank account and receive your funds in the standard 2-5 business days."
          options={[
            {
              label: "Link account with Plaid",
              helperText:
                "Instantly verify and connect your bank accounts securely",
              value: "standard-plaid",
              avatar: {
                format: "square",
                type: "image",
                image: plaidIcon
              }
            },
            {
              label: "Set up account manually",
              helperText: "Manually enter your routing and account numbers",
              value: "standard-manual",
              avatar: avatarBankAccount
            }
          ]}
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onContinue={value => {
            if (value === "standard-plaid") {
              standardPlaidLink.open();
            } else {
              history.push(basePath + "/" + value);
            }
          }}
        />
      </Route>

      <Route path={basePath + "/wallet"}>
        <FormCreateInternalAccount
          type="Banking"
          currency="USD"
          setStandardPayoutDestination
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onContinue={onSuccess}
        />
      </Route>

      <Route path={basePath + "/instant-card"}>
        <FormAddDebitCard
          setInstantPayoutPreference
          onBack={() => {
            history.push(basePath + "/instant");
          }}
          onSuccess={onSuccess}
          withPanel
        />
      </Route>

      <Route path={basePath + "/instant-plaid"}>
        <FormSelectAccount
          title="Select bank account"
          description="Select a bank account as your instant payout method"
          setInstantPayoutDestination
          accounts={createdAccounts}
          onBack={() => {
            history.push(basePath + "/instant");
          }}
          onContinue={onSuccess}
        />
      </Route>

      <Route path={basePath + "/instant-manual"}>
        <FormManuallyAddAccount
          rtpRequired
          setInstantPayoutDestination
          onBack={() => {
            history.push(basePath + "/instant");
          }}
          onSuccess={onSuccess}
          withPanel
        />
      </Route>

      <Route path={basePath + "/standard-plaid"}>
        <FormSelectAccount
          title="Select bank account"
          description="Select a bank account as your payout method"
          setStandardPayoutDestination
          accounts={createdAccounts}
          onBack={() => {
            history.push(basePath + "/standard");
          }}
          onContinue={onSuccess}
        />
      </Route>

      <Route path={basePath + "/standard-manual"}>
        <FormManuallyAddAccount
          setStandardPayoutDestination
          onBack={() => {
            history.push(basePath + "/standard");
          }}
          onSuccess={onSuccess}
          withPanel
        />
      </Route>

      <Route path={basePath + "/international"}>
        <FormManuallyAddInternationalAccount
          onBack={() => {
            history.push(basePath + "/type");
          }}
          onSuccess={onSuccess}
          withPanel
        />
      </Route>

      <Redirect path={basePath} to={basePath + "/type"} />
    </Switch>
  );
};
