import {
  WSActions,
  WSForm,
  WSFormProps,
  WSList,
  WSPanel,
  WSSectionToolbar
} from "@wingspanhq/fe-component-library";
import merge from "lodash/merge";
import { WSFormErrorSummary } from "../../../../shared/components/WSFormErrorSummary";
import { FormPartialDataPerson, FormPartialPerson } from "../FormPartialPerson";
import { emptyValues as emptyValuesPerson } from "../FormPartialPerson/emptyValues";
import { BeneficialOwners } from "./BeneficialOwners";
import { calculateTotalStake } from "./helpers";
import { getValidationSchema } from "./validationSchema";

export type FormData = {
  representative: FormPartialDataPerson;
  beneficialOwners: FormPartialDataPerson[];
  allOwnersAdded: boolean;
};

type Props = {
  defaultValues?: WSFormProps<FormData>["defaultValues"];
  onSubmit: WSFormProps<FormData>["onSubmit"];
  onBack?: () => void;
  country: string;
  includeBeneficialOwners?: boolean;
  requireTaxId?: boolean;
  singleOwnershipStake?: boolean;
};

export const FormRepresentative: React.FC<Props> = ({
  defaultValues,
  onBack,
  onSubmit,
  country,
  includeBeneficialOwners,
  singleOwnershipStake,
  requireTaxId
}) => {
  const emptyValues = {
    representative: {
      ...emptyValuesPerson,
      country,
      ownershipStake: singleOwnershipStake ? 100 : 0
    },
    beneficialOwners: [],
    allOwnersAdded: false
  };

  return (
    <WSForm<FormData>
      defaultValues={merge(emptyValues, defaultValues)}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema({
        includeBeneficialOwners,
        requireTaxId
      })}
    >
      <WSList gap="2XL">
        <WSPanel>
          <WSSectionToolbar
            mb="M"
            title="Authorized representative information (You)"
          />

          <FormPartialPerson
            type="Representative"
            name="representative"
            showOwnershipStake={includeBeneficialOwners}
            singleOwnershipStake={singleOwnershipStake}
            requireTaxId={requireTaxId}
          />
        </WSPanel>

        {includeBeneficialOwners ? (
          <WSForm.Value name="representative.ownershipStake">
            {representativeOwnershipStake => {
              const beneficialOwnersVisible =
                !representativeOwnershipStake ||
                representativeOwnershipStake <= 75;

              return (
                <BeneficialOwners
                  mb="2XL"
                  hidden={!beneficialOwnersVisible}
                  country={country}
                />
              );
            }}
          </WSForm.Value>
        ) : null}

        <WSFormErrorSummary />

        <WSForm.Values names={["representative", "beneficialOwners"]}>
          {values => {
            const representativeOwnershipStake =
              values.representative?.ownershipStake || 0;
            const beneficialOwners = values.beneficialOwners || 0;

            const stakesSum = calculateTotalStake(
              representativeOwnershipStake,
              beneficialOwners
            );

            return (
              <WSActions
                alignment="fill"
                buttons={[
                  {
                    label: "Back",
                    onClick: onBack,
                    kind: "Secondary",
                    type: "button",
                    visible: !!onBack
                  },
                  {
                    label: "Continue",
                    disabled: stakesSum > 100
                  }
                ]}
              />
            );
          }}
        </WSForm.Values>
      </WSList>
    </WSForm>
  );
};
