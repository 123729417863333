import footprint, { FootprintComponentKind } from "@onefootprint/footprint-js";
import { queryCache } from "react-query";
import { useHistory } from "react-router-dom";
import { PATH_ONBOARDING_VERIFICAIONS_BANKING } from "../../modules/Onboarding";
import { QUERY_NOTIFICATIONS_NOTIFICATIONS } from "../../query/notifications/keys";
import { QUERY_VERIFICATIONS } from "../../query/onboarding/queries/useQueryVerifications";
import { getVerificationMissingData } from "../../services/api/onboarding/missingFields";
import { footprintAppearanceConfig } from "../constants/footprint";

class VerificationService {
  async completeBankingVerification(history: ReturnType<typeof useHistory>) {
    try {
      const missingData = await getVerificationMissingData("Banking");

      if (missingData.requiredFields && missingData.requiredFields.length > 0) {
        history.push(PATH_ONBOARDING_VERIFICAIONS_BANKING);
      } else if (missingData.completeOnboardingToken) {
        const component = footprint.init({
          kind: FootprintComponentKind.Verify,
          variant: "modal",
          authToken: missingData.completeOnboardingToken,
          onComplete: () => {
            queryCache.invalidateQueries(QUERY_VERIFICATIONS);
            queryCache.invalidateQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);
          },
          options: {
            showLogo: false
          },
          appearance: footprintAppearanceConfig
        });
        component.render();
      } else {
        throw new Error(
          "No completeOnboardingToken or requiredFields provided"
        );
      }
    } catch (e) {
      console.error("Error while complete verificaiton", e);
    }
  }

  async completeTaxVerification(history: ReturnType<typeof useHistory>) {
    try {
      const missingData = await getVerificationMissingData("Banking");

      if (missingData.requiredFields && missingData.requiredFields.length > 0) {
        history.push(PATH_ONBOARDING_VERIFICAIONS_BANKING);
      } else if (missingData.completeOnboardingToken) {
        const component = footprint.init({
          kind: FootprintComponentKind.Verify,
          variant: "modal",
          authToken: missingData.completeOnboardingToken,
          onComplete: () => {
            queryCache.invalidateQueries(QUERY_VERIFICATIONS);
            queryCache.invalidateQueries(QUERY_NOTIFICATIONS_NOTIFICATIONS);
          },
          options: {
            showLogo: false
          },
          appearance: footprintAppearanceConfig
        });
        component.render();
      } else {
        throw new Error(
          "No completeOnboardingToken or requiredFields provided"
        );
      }
    } catch (e) {
      console.error("Error while complete verificaiton", e);
    }
  }
}

export const verificationService = new VerificationService();
