import { WSIconName } from "@wingspanhq/fe-component-library";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";

export const REQUIREMENT_TYPE_TO_LABEL: {
  [key in RequirementType]: string;
} = {
  [RequirementType.Signature]: "e-Signature",
  [RequirementType.SharedFile]: "File upload"
};

export const REQUIREMENT_TYPE_TO_ICON: {
  [key in RequirementType]: WSIconName;
} = {
  [RequirementType.Signature]: "edit-line",
  [RequirementType.SharedFile]: "upload"
};

export const selectorRequirementHumanReadableType = (type: RequirementType) => {
  return REQUIREMENT_TYPE_TO_LABEL[type];
};

export const selectorRequirementIcon = (type: RequirementType) => {
  return REQUIREMENT_TYPE_TO_ICON[type];
};
