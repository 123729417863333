import * as Yup from "yup";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { MIME_TYPES_VALUES } from "./utils";

export const sharedFileValidationSchema = Yup.object().shape({
  instructions: Yup.mixed().when("type", {
    is: RequirementType.SharedFile,
    then: Yup.string(),
    otherwise: Yup.mixed().notRequired()
  }),
  mimeTypes: Yup.mixed().when("type", {
    is: RequirementType.SharedFile,
    then: Yup.object()
      .test("isNotEmpty", "At least one MIME type is required", value => {
        return (
          value &&
          Object.keys(value).length > 0 &&
          Object.keys(value).every(
            key => MIME_TYPES_VALUES[key as keyof typeof MIME_TYPES_VALUES]
          ) &&
          Object.values(value).some(Boolean)
        );
      })
      .required("Required"),
    otherwise: Yup.mixed().notRequired()
  })
});
