import { PayerPayeeEngagementStatus } from "@wingspanhq/payments/dist/interfaces/payerPayeeEngagement";
import {
  RequirementStatus,
  RequirementType
} from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import {
  AllEngagementsByPayerIds,
  IPayerEngagementExtended,
  IRequirementExtended
} from "../queries/useQueryAllPayersWithEngagements";
import { RequirementEmbeddedStatus } from "@wingspanhq/payments/dist/interfaces";

const getActiveOldRequirements = (engagement: IPayerEngagementExtended) => {
  if (engagement.status !== PayerPayeeEngagementStatus.Active) {
    return [];
  }

  const eligibilityRequirements = engagement.eligibilityRequirements.filter(
    requirement =>
      requirement.status !== RequirementStatus.Complete &&
      !requirement.document?.events?.memberSignedAt
  );

  return eligibilityRequirements;
};

const getActiveRequirements = (engagement: IPayerEngagementExtended) => {
  if (engagement.status !== PayerPayeeEngagementStatus.Active) {
    return [];
  }

  let requirements: IRequirementExtended[] = [];

  if (engagement.engagementId) {
    requirements = engagement.requirements.filter(requirement => {
      if (requirement.requirementType === RequirementType.SharedFile) {
        return requirement.status !== RequirementEmbeddedStatus.Complete;
      } else if (requirement.requirementType === RequirementType.Signature) {
        return (
          requirement.status !== RequirementEmbeddedStatus.Complete &&
          requirement.dataSourceStatus !== RequirementEmbeddedStatus.Complete &&
          requirement.document &&
          !requirement.document?.events?.payeeSignedAt
        );
      }

      throw new Error(
        `Unknown requirement type: ${requirement.requirementType}`
      );
    });
  }

  return requirements;
};

const getActiveEngagements = (engagements: IPayerEngagementExtended[]) => {
  const isEngagementsEnabled = selectorIsEngagementsEnabled(engagements);

  return engagements.filter(engagement =>
    isEngagementsEnabled
      ? getActiveRequirements(engagement).length > 0
      : getActiveOldRequirements(engagement).length > 0
  );
};

export const selectorIsEngagementsEnabled = (
  engagements: { engagementId?: string }[]
) => {
  return engagements.some(engagement => engagement.engagementId);
};

export const getActivePayersWithEngagement = (
  payersEngagements: AllEngagementsByPayerIds
) => {
  return payersEngagements
    .filter(({ payer, engagements }) => {
      return getActiveEngagements(engagements).length > 0;
    })
    .map(({ payer, engagements }) => {
      return {
        payer,
        engagements: getActiveEngagements(engagements)
      };
    });
};
