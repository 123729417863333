import {
  ContextItem,
  RequirementContextType,
  RequirementTableCallback,
  RequirementTableItemData,
  RequirementTableRowConfig
} from "./types";
import { useSignatureRequirementTableConfig } from "./modules/Signature";
import { RequirementType } from "@wingspanhq/payments/dist/interfaces/eligibilityRequirement";
import { useSharedFileRequirementTableConfig } from "./modules/SharedFile";
import { IRequirementResponse } from "@wingspanhq/payments/dist/interfaces/api/requirement";

export const useRequirementTableConfig = (
  contextType: RequirementContextType,
  callback: RequirementTableCallback
) => {
  const signatureConfig = useSignatureRequirementTableConfig(callback);
  const sharedFileConfig = useSharedFileRequirementTableConfig(callback);

  const config: RequirementTableRowConfig = {
    [RequirementType.Signature]: signatureConfig,
    [RequirementType.SharedFile]: sharedFileConfig
  };

  return {
    renderName(item: RequirementTableItemData, context: ContextItem) {
      return config[item.requirementType].nameCell[contextType](
        item as IRequirementResponse,
        context
      );
    },
    renderType(item: RequirementTableItemData, context: ContextItem) {
      return config[item.requirementType].typeCell[contextType](
        item as IRequirementResponse,
        context
      );
    },
    renderStatus(item: RequirementTableItemData, context: ContextItem) {
      return config[item.requirementType].statusCell[contextType](
        item as IRequirementResponse,
        context
      );
    },
    renderRowActions(item: RequirementTableItemData, context: ContextItem) {
      return config[item.requirementType].rowActions[contextType](
        item as IRequirementResponse,
        context
      );
    },
    renderRowMenuActions(item: RequirementTableItemData, context: ContextItem) {
      return config[item.requirementType].rowMenuActions[contextType](
        item as IRequirementResponse,
        context
      );
    },
    fetchContext: async (
      requirements: RequirementTableItemData[]
    ): Promise<ContextItem> => {
      let context: ContextItem = {};

      if (sharedFileConfig?.fetchContext) {
        context[
          RequirementType.SharedFile
        ] = await sharedFileConfig?.fetchContext(
          requirements as IRequirementResponse[]
        );
      }

      if (signatureConfig.fetchContext) {
        context[
          RequirementType.Signature
        ] = await signatureConfig?.fetchContext(
          requirements as IRequirementResponse[]
        );
      }

      return context;
    }
  } as const;
};
