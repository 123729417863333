import { useHistory } from "react-router-dom";
import { useBrowserPageTitle } from "../../../../shared/hooks/useBrowserPageTitle";
import { PageHeader } from "../../../../shared/components/PageHeader";
import { PAYEES_ROOT_PATH } from "../../utils";
import {
  WSAlert,
  WSEmptyState,
  WSInfiniteScroll,
  WSPage,
  WSText
} from "@wingspanhq/fe-component-library";
import { useState } from "react";
import { WSQueries } from "../../../../query/WSQuery";
import { PayeeRowsTable } from "../../components/PayeeRowsTable";
import { usePayeeRowsQuery } from "../../../../query/search/payee/queries/usePayeeRowsQuery";
import { PayerPayeeStatus } from "@wingspanhq/payments/dist/interfaces";
import { RedactedPayeeRowsQuery } from "../../../../query/search/payee/queries/utils";

const ARCHIVED_PAYEES_FILTERS: RedactedPayeeRowsQuery = {
  filter: {
    "payerOwnedData.status": PayerPayeeStatus.Inactive
  }
};

export const RouteArchivedPayees: React.FC = () => {
  useBrowserPageTitle("Archived contractors");
  const history = useHistory();
  const [hideInfo, setHideInfo] = useState<boolean>(false);

  const queryPayeeRows = usePayeeRowsQuery(ARCHIVED_PAYEES_FILTERS);

  return (
    <WSPage>
      <PageHeader
        mb="L"
        title="Contractors"
        rootPath={PAYEES_ROOT_PATH}
        breadcrumbs={[
          {
            label: "Contractors",
            onClick: () => {
              history.push(PAYEES_ROOT_PATH);
            }
          },
          {
            label: "Archived contractors"
          }
        ]}
      />
      <WSText.Heading3 mb="L" weight="medium">
        Archived contractors
      </WSText.Heading3>
      <WSQueries queries={{ queryPayeeRows }}>
        {({ queryPayeeRowsData }) => {
          return (
            <>
              {/* <WSFilters
                mt="L"
                mb="XL"
                values={{}}
                onFilter={() => {}}
                primaryFilters={[]}
                info={{
                  count
                }}
              /> */}
              {queryPayeeRowsData.length === 0 ? (
                <WSEmptyState
                  hasBorder
                  fullWidth
                  bgColor="gray"
                  type="people"
                  title="No archived contractors found"
                  description="Once a contractor is archived, they will appear here."
                />
              ) : (
                <>
                  {!hideInfo && (
                    <WSAlert
                      icon="info-circle"
                      onDismiss={() => {
                        setHideInfo(prev => !prev);
                      }}
                      size="M"
                      theme="info"
                      title="Archived contractors"
                    >
                      <WSText.ParagraphSm weight="book" color="gray700">
                        After a contractor is archived, no new payments can be
                        created or sent to the contractor. The contractor will
                        be made archived and hidden from engagements they were
                        on.
                        <WSText.ParagraphSm
                          mt="S"
                          weight="book"
                          color="gray700"
                        >
                          Existing invoices and the contractor profile will
                          remain visible until you delete them. You can always
                          restore the contractor when needed. The contractor’s
                          eligibility status will be recalculated upon restore.
                        </WSText.ParagraphSm>
                      </WSText.ParagraphSm>
                    </WSAlert>
                  )}
                  <WSInfiniteScroll
                    mt="M"
                    onLoad={() => {
                      queryPayeeRows.fetchMore();
                    }}
                    loadMore={queryPayeeRowsData.length > 0}
                    endOfList={!queryPayeeRows.canFetchMore}
                    loading={!!queryPayeeRows.isFetchingMore}
                  >
                    <PayeeRowsTable
                      mt="XL"
                      filters={ARCHIVED_PAYEES_FILTERS}
                      payees={queryPayeeRowsData}
                      isArchivedOnly
                    />
                  </WSInfiniteScroll>
                </>
              )}
            </>
          );
        }}
      </WSQueries>
    </WSPage>
  );
};
